// TODO When entering edit mode, gives error 'Invalid time value' in console. Needs to be fixed.

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FaCalendarAlt, FaClock, FaCheckCircle, FaTimesCircle, FaPalette, FaEdit, FaSave, FaTimes } from 'react-icons/fa';
import CustomDatePicker from '../../components/CustomDatePicker';
import { ChromePicker } from 'react-color';
import { useNotifications } from '../../context/NotificationContext';
import { useProducts } from '../../hooks';

const ViewProduct = () => {
    const { id } = useParams();
    const { product, setProduct, fetchProductById, updateProduct } = useProducts();
    const [isEditing, setIsEditing] = useState(false);
    const { addNotification } = useNotifications();

    useEffect(() => {
        
        fetchProductById(id);
    }, [id]);

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
    };

    const handleSave = () => {
        updateProduct(id, product, setIsEditing, addNotification);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProduct(prevProduct => ({ ...prevProduct, [name]: value }));
    };

    const handleColorChange = (color) => {
        setProduct(prevProduct => ({ ...prevProduct, color: color.hex }));
    };

    const handleDateChange = (name, date) => {
        const formattedDate = date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' }).replace(/\//g, '-');
        setProduct(prevProduct => ({ ...prevProduct, [name]: formattedDate }));
    };

    const calculatePercentageOfYear = (dateStr) => {
        if (!dateStr) {
            return 0;
        }
        const [month, day] = dateStr.split('-').map(Number);
        const date = new Date(2023, month - 1, day);
        const startOfYear = new Date(2023, 0, 1);
        const endOfYear = new Date(2023, 11, 31);
        return ((date - startOfYear) / (endOfYear - startOfYear)) * 100;
    };

    const periodStartPercent = calculatePercentageOfYear(product?.period_start);
    const periodEndPercent = calculatePercentageOfYear(product?.period_end);

    const months = ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Des"];

    return (
        <div className="max-w-2xl mx-auto p-8 bg-white rounded-lg shadow-lg">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-3xl font-bold text-gray-800">Vis Produkt</h1>
                {isEditing ? (
                    <div>
                        <button
                            onClick={handleSave}
                            className="bg-green-500 text-white p-2 rounded-lg shadow-md hover:bg-green-600 transition duration-150 ease-in-out mr-2"
                        >
                            <FaSave />
                        </button>
                        <button
                            onClick={handleEditToggle}
                            className="bg-red-500 text-white p-2 rounded-lg shadow-md hover:bg-red-600 transition duration-150 ease-in-out"
                        >
                            <FaTimes />
                        </button>
                    </div>
                ) : (
                    <button
                        onClick={handleEditToggle}
                        className="bg-green-grave text-white p-2 rounded-lg shadow-md hover:bg-orange-grave transition duration-150 ease-in-out"
                    >
                        <FaEdit />
                    </button>
                )}
            </div>
            {isEditing ? (
                <form className="space-y-6">
                    <div>
                        <label htmlFor="name" className="block text-lg font-medium text-gray-700">Navn:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={product.name}
                            onChange={handleChange}
                            className="mt-2 block w-full border-gray-300 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
                            placeholder="Skriv inn produktnavn"
                        />
                    </div>

                    <div>
                        <label htmlFor="description" className="block text-lg font-medium text-gray-700">Beskrivelse:</label>
                        <textarea
                            id="description"
                            name="description"
                            value={product.description}
                            onChange={handleChange}
                            className="mt-2 block w-full border-gray-300 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
                            placeholder="Skriv inn produktbeskrivelse"
                            rows="4"
                        ></textarea>
                    </div>

                    <div className="grid grid-cols-2 gap-6">
                        <div>
                            <label htmlFor="period_start" className="block text-lg font-medium text-gray-700">Periode Start:</label>
                            <CustomDatePicker
                                selectedDate={new Date(product.period_start)}
                                onChange={(date) => handleDateChange('period_start', date)}
                                className="mt-2 block w-full border-gray-300 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
                            />
                        </div>

                        <div>
                            <label htmlFor="period_end" className="block text-lg font-medium text-gray-700">Periode Slutt:</label>
                            <CustomDatePicker
                                selectedDate={new Date(product.period_end)}
                                onChange={(date) => handleDateChange('period_end', date)}
                                className="mt-2 block w-full border-gray-300 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
                            />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="color" className="block text-lg font-medium text-gray-700">Farge:</label>
                        <div className="mt-2">
                            <ChromePicker
                                color={product.color}
                                onChangeComplete={handleColorChange}
                                className="w-full"
                            />
                        </div>
                    </div>
                </form>
            ) : (
                <div className="space-y-4">
                    <div className="mb-6">
                        <h2 className="text-2xl font-semibold text-gray-700">{product.name}</h2>
                        <p className="text-gray-600">{product.description}</p>
                    </div>
                    <div className="space-y-4">
                        <div className="flex items-center text-gray-700">
                            <FaClock className="mr-2 text-orange-grave" />
                            <span>Opprettet: {new Date(product.created_at).toLocaleString('nb-NO')}</span>
                        </div>
                        <div className="flex items-center text-gray-700">
                            <FaClock className="mr-2 text-orange-grave" />
                            <span>Oppdatert: {new Date(product.updated_at).toLocaleString('nb-NO')}</span>
                        </div>
                        <div className="flex items-center text-gray-700">
                            {product.active ? (
                                <FaCheckCircle className="mr-2 text-green-grave" />
                            ) : (
                                <FaTimesCircle className="mr-2 text-red-500" />
                            )}
                            <span>Aktiv: {product.active ? 'Ja' : 'Nei'}</span>
                        </div>
                        <div className="flex items-center text-gray-700">
                            <FaCalendarAlt className="mr-2 text-orange-grave" />
                            <span>Periode: {product.period_start} - {product.period_end}</span>
                        </div>
                        <div className="relative w-full bg-gray-500 rounded-full h-4">
                            <div
                                className="absolute top-0 h-4 rounded-full bg-green-grave"
                                style={{
                                    width: `${periodEndPercent - periodStartPercent}%`,
                                    left: `${periodStartPercent}%`,
                                }}
                            ></div>
                            {months.map((month, index) => (
                                <span
                                    key={index}
                                    className="absolute text-xs text-white ml-5"
                                    style={{ left: `${(index / 11) * 90}%` }}
                                >
                                    {month}
                                </span>
                            ))}
                        </div>
                        <div className="flex items-center text-gray-700">
                            <FaPalette className="mr-2 text-orange-grave" />
                            <span>Farge: {product.color}</span>
                            <div
                                className="ml-2 w-6 h-6 rounded-full border border-gray-300"
                                style={{ backgroundColor: product.color }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ViewProduct;