import { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';

const useSpecialDays = () => {
  const [specialDays, setSpecialDays] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({ totalItems: 0, totalPages: 0 });

  // Utility to extract backend error messages
  const getErrorMessage = (err, fallbackMessage) => {
    return err.response?.data?.error || err.response?.data?.message || fallbackMessage;
  };

  // Fetch all special days with parameters
  const fetchSpecialDays = async ({ search = '', sort = 'date', order = 'ASC', page = 1, limit = 10 }) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get('/api/special-days', {
        params: { search, sort, order, page, limit },
      });
      setSpecialDays(response.data.specialDays);
      setPagination({ totalItems: response.data.totalItems, totalPages: response.data.totalPages });
    } catch (err) {
      setError(getErrorMessage(err, 'Kunne ikke hente spesielle dager.'));
    } finally {
      setLoading(false);
    }
  };

  // Fetch special days for a specific gravestone
  const fetchSpecialDaysByGravestone = async (gravestoneId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get(`/api/special-days/gravestone/${gravestoneId}`);
      setSpecialDays(response.data);
    } catch (err) {
      setError(getErrorMessage(err, 'Kunne ikke hente spesielle dager for gravsteinen.'));
    } finally {
      setLoading(false);
    }
  };

  // Create a new special day
  const createSpecialDay = async (data) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.post('/api/special-days', data);
      setSpecialDays((prev) => [...prev, { ...data, id: response.data.id }]); // Add new special day locally
      return response.data.id;
    } catch (err) {
      setError(getErrorMessage(err, 'Kunne ikke opprette spesiell dag.'));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Update an existing special day
  const updateSpecialDay = async (id, data) => {
    setLoading(true);
    setError(null);
    try {
      await axiosInstance.put(`/api/special-days/${id}`, data);
      setSpecialDays((prev) =>
        prev.map((day) => (day.id === id ? { ...day, ...data } : day)) // Update local state
      );
    } catch (err) {
      setError(getErrorMessage(err, 'Kunne ikke oppdatere spesiell dag.'));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Set a special day as active
  const setActiveSpecialDay = async (id, gravestoneId) => {
    setLoading(true);
    setError(null);
    try {
      await axiosInstance.put(`/api/special-days/set-active/${id}`, { gravestone_id: gravestoneId });
      setSpecialDays((prev) =>
        prev.map((day) => ({ ...day, active: day.id === id }))
      ); // Update active status locally
    } catch (err) {
      setError(getErrorMessage(err, 'Kunne ikke sette spesiell dag som aktiv.'));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Delete a special day
  const deleteSpecialDay = async (id) => {
    setLoading(true);
    setError(null);
    try {
      await axiosInstance.delete(`/api/special-days/${id}`);
      setSpecialDays((prev) => prev.filter((day) => day.id !== id)); // Remove locally
    } catch (err) {
      setError(getErrorMessage(err, 'Kunne ikke slette spesiell dag.'));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    specialDays,
    pagination,
    loading,
    error,
    fetchSpecialDays,
    fetchSpecialDaysByGravestone,
    createSpecialDay,
    updateSpecialDay,
    setActiveSpecialDay,
    deleteSpecialDay,
  };
};

export default useSpecialDays;