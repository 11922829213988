import React, { useEffect, useRef } from 'react';

const ContextMenu = ({ items, position, onClose }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      ref={menuRef}
      className="fixed z-50 bg-white shadow-lg rounded-lg p-2"
      style={{ top: position.y, left: position.x }}
    >
      <ul className="list-none m-0 p-0">
        {items.map((item, index) => (
          <li
            key={index}
            className={`cursor-pointer flex items-center px-4 py-2 ${
              item.disabled ? 'text-gray-400 cursor-not-allowed' : 'hover:bg-gray-200'
            }`}
            onClick={() => {
              if (!item.disabled) {
                item.onClick();
                onClose(); // Close the context menu after an item is clicked
              }
            }}
          >
            {item.icon && (
              <span className={`mr-2 ${item.iconColor || 'text-gray-600'}`}>
                {React.createElement(item.icon)} {/* Render the icon with color */}
              </span>
            )}
            <span>{item.label}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContextMenu;