import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  FaTimes,
  FaBars,
  FaClipboardList,
  FaRegCalendarCheck,
  FaTachometerAlt,
  FaUser,
  FaChurch,
  FaMonument,
} from 'react-icons/fa';
import { FaRightFromBracket } from 'react-icons/fa6';
import { AuthContext } from '../context/AuthContext';
import { useSwipeable } from 'react-swipeable';
import PunchClockMini from './PunchClockMini';

const MobileMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { user } = useContext(AuthContext);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const workerMenuItems = [
    { icon: FaTachometerAlt, label: 'Dashboard', to: '/' },
    { icon: FaChurch, label: 'Kirkegårder', to: '/kirkegarder' },
    { icon: FaMonument, label: 'Gravsteiner', to: '/gravsteiner' },
    { icon: FaClipboardList, label: 'Mine Arbeidslister', to: '/mine-arbeidslister' },
    { icon: FaRegCalendarCheck, label: 'Mine Besøk', to: '/mine-besok' },
    { icon: FaUser, label: 'Din Profil', to: `/brukere/${user?.id}` },
    // Add additional items as needed
  ];

  const handlers = useSwipeable({
    onSwipedRight: () => setIsMenuOpen(false),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <>
      {/* Hamburger Menu Button */}
      <button onClick={toggleMenu} className="fixed top-4 right-4 z-50 sm:hidden">
        {isMenuOpen ? <FaTimes size={24} className="text-white" /> : <FaBars size={24} />}
      </button>

      {/* Fullscreen Menu */}
      <div
        {...handlers}
        className={`fixed inset-0 z-40 flex flex-col bg-gray-800 text-white transform ${
          isMenuOpen ? 'translate-x-0' : 'translate-x-full'
        } transition-transform duration-300 ease-in-out overflow-y-auto`}
      >
        {/* Menu Close Button */}
        <button onClick={toggleMenu} className="self-end p-4 text-white">
          <FaTimes size={24} />
        </button>

        {/* Menu Items */}
          <div className="flex-grow flex flex-col items-center justify-center">
            {workerMenuItems.map((item, index) => (
              <Link
                key={index}
                to={item.to}
                className="flex items-center text-xl font-medium my-4 hover:text-gray-300 transition duration-150"
                onClick={() => setIsMenuOpen(false)}
              >
                <item.icon size={24} className="mr-2" />
                <span>{item.label}</span>
              </Link>
            ))}
          </div>
          <div className="p-4 self-end">
            <button
              onClick={() => {
                setIsMenuOpen(false);
                window.location.href = '/loggut';
              }}
              className="flex items-center text-xl text-red-500 font-medium my-4 hover:text-gray-300 transition duration-150"
            >
              <FaRightFromBracket size={24} className="mr-2" />
              <span>Logg Ut</span>
            </button>
          </div>

          {/* PunchClockMini Component at the Bottom */}
        {user && (
          <div className="p-4 border-t border-gray-700">
            <PunchClockMini userId={user.id} closeMenu={() => setIsMenuOpen(false)} />
          </div>
        )}
      </div>
    </>
  );
};

export default MobileMenu;