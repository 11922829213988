import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import {
  FaCalendarAlt,
  FaCalendarCheck,
  FaMapMarkerAlt,
  FaTasks,
  FaRegClock,
} from "react-icons/fa";
import WorkorderSkeleton from "../../components/WorkorderSkeleton";

const WorkordersUser = () => {
  const [workOrders, setWorkOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ignoreFinished, setIgnoreFinished] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useContext(AuthContext);

  const apiURL = process.env.REACT_APP_API_BASE_URL || "";

  const fetchWorkOrders = useCallback(async () => {
    if (!user || !user.id) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch(
        `${apiURL}/api/user/workorders?userId=${user.id}&ignoreFinished=${ignoreFinished}`
      );
      const workOrdersData = await response.json();
      setWorkOrders(workOrdersData.workorders);
    } catch (error) {
      console.error("Failed to fetch work orders:", error);
      setError("Failed to load work orders. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  }, [apiURL, user, ignoreFinished]);

  useEffect(() => {
    fetchWorkOrders();
  }, [fetchWorkOrders]);

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toLocaleDateString("nb-NO", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <div className="p-4">
      <h1 className="text-3xl font-semibold text-gray-800 mb-4">
        Dine Arbeidsordre
      </h1>

      {/* Filter Option */}
      <div className="flex justify-end mb-6">
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={!ignoreFinished}
            onChange={() => setIgnoreFinished(!ignoreFinished)}
            className="form-checkbox h-4 w-4 text-blue-600"
          />
          <span className="ml-2 text-gray-700">Vis ferdige arbeidsordre</span>
        </label>
      </div>

      {isLoading ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {[...Array(6)].map((_, index) => (
            <WorkorderSkeleton key={index} />
          ))}
        </div>
      ) : error ? (
        <div className="text-center text-red-500">
          <p>{error}</p>
          <button
            onClick={fetchWorkOrders}
            className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
          >
            Prøv igjen
          </button>
        </div>
      ) : workOrders.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {workOrders.map((order) => (
            <Link
              to={`/arbeidslister/${order.id}`}
              key={order.id}
              className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300"
            >
              <div className="p-6">
                <h2 className="text-xl font-semibold text-gray-800 mb-4">
                  {order.name}
                </h2>

                {/* Progress Bar */}
                <div className="w-full bg-gray-200 rounded-full h-2 mb-4">
                  <div
                    className="bg-green-500 h-2 rounded-full"
                    style={{
                      width: `${
                        (order.completed_visits_count / order.gravestone_count) *
                        100
                      }%`,
                    }}
                  ></div>
                </div>

                <div className="flex items-center mb-2 text-gray-600">
                  <FaCalendarAlt className="w-5 h-5 mr-2 text-blue-500" />
                  <p className="text-sm">
                    Start: {formatDate(order.work_date)}
                  </p>
                </div>

                <div className="flex items-center mb-2 text-gray-600">
                  <FaCalendarCheck className="w-5 h-5 mr-2 text-red-500" />
                  <p className="text-sm">Frist: {formatDate(order.due_date)}</p>
                </div>

                {order.graveyard_names && (
                  <div className="flex items-center mb-2 text-gray-600">
                    <FaMapMarkerAlt className="w-5 h-5 mr-2 text-green-500" />
                    <p className="text-sm">
                      Kirkegårder: {order.graveyard_names}
                    </p>
                  </div>
                )}

                <div className="flex items-center mb-2 text-gray-600">
                  <FaTasks className="w-5 h-5 mr-2 text-yellow-500" />
                  <p className="text-sm">
                    Gravsteiner: {order.gravestone_count} (
                    {order.completed_visits_count} ferdige)
                  </p>
                </div>

                <div className="flex items-center mb-4 text-gray-600">
                  <FaRegClock className="w-5 h-5 mr-2 text-purple-500" />
                  <span
                    className={`ml-2 inline-block px-2 py-1 text-xs font-semibold rounded-full ${
                      order.status_name === "Completed"
                        ? "bg-green-100 text-green-800"
                        : order.status_name === "In Progress"
                        ? "bg-blue-100 text-blue-800"
                        : "bg-yellow-100 text-yellow-800"
                    }`}
                  >
                    {order.status_name}
                  </span>
                </div>

                <p className="text-sm text-gray-600">
                  <span className="font-semibold">Aktiviteter:</span>{" "}
                  {order.activity_names}
                </p>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-500">Ingen arbeidsordre funnet.</p>
      )}
    </div>
  );
};

export default WorkordersUser;