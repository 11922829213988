import React, { useEffect, useRef, useState } from 'react';
import { loadGoogleMaps } from '../hooks/GoogleMapsLoader';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import useInitiateVisit from '../hooks/useInitiateVisit';
import axiosInstance from '../utils/axiosInstance';

const GravestoneMapAll = ({ gravestones, workorder_id = '' }) => {
  const mapContainerRef = useRef(null);
  const [mapSettings, setMapSettings] = useState(null);
  const initiateVisit = useInitiateVisit();

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axiosInstance.get('/api/site_settings/map_settings');
        setMapSettings(response.data.setting_value);
      } catch (error) {
        console.error('Error fetching map settings:', error);
      }
    };

    fetchSettings();
  }, [workorder_id]);

  useEffect(() => {
    if (!mapSettings) return;

    let mapInstance;
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    let watchId;
    let userMarker;

    loadGoogleMaps(apiKey).then((google) => {
      if (mapContainerRef.current) {
        const center = mapSettings.defaultCenter || calculateCenter(gravestones);
        mapInstance = new google.maps.Map(mapContainerRef.current, {
          center: center,
          zoom: mapSettings.defaultZoom || 10,
          mapTypeId:
            google.maps.MapTypeId[mapSettings.mapType.toUpperCase()] ||
            google.maps.MapTypeId.SATELLITE,
          disableDefaultUI: true,
          zoomControl: true,
          streetViewControl: false,
          fullscreenControl: false,
        });

        if (mapSettings.userTracking) {
          watchId = navigator.geolocation.watchPosition(
            (position) => {
              const newPos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              

              if (!userMarker) {
                userMarker = new google.maps.Marker({
                  map: mapInstance,
                  position: newPos,
                  icon: {
                    url: mapSettings.markerIcon || '/images/user_map_marker.png',
                    scaledSize: new google.maps.Size(
                      mapSettings.markerScale || 40,
                      mapSettings.markerScale || 40
                    ),
                  },
                });
              } else {
                userMarker.setPosition(newPos);
              }
            },
            (error) => {
              console.error('Error watching position:', error);
            },
            {
              enableHighAccuracy: true,
              maximumAge: mapSettings.geolocationAccuracy || 10000,
              timeout: 5000,
            }
          );
        }

        // Load and add markers after map is initialized
        apiIsLoaded(
          mapInstance,
          google.maps,
          gravestones,
          workorder_id,
          initiateVisit,
          mapSettings
        );
      }
    });

    return () => {
      if (mapInstance) {
        mapInstance = null;
      }
      navigator.geolocation.clearWatch(watchId);
    };
  }, [mapSettings]);

  return (
    <div
      ref={mapContainerRef}
      style={{ height: '100vh', width: '100%', touchAction: 'none' }}
    ></div>
  );
};

function calculateCenter(gravestones) {
  const validGravestones = gravestones.filter(g => 
    g.latitude !== 'NULL' && g.longitude !== 'NULL' &&
    !isNaN(parseFloat(g.latitude)) && !isNaN(parseFloat(g.longitude))
  );

  if (validGravestones.length === 0) {
    return { lat: 59.95, lng: 30.33 }; // Default center
  }

  const total = validGravestones.reduce((acc, { latitude, longitude }) => {
    acc.lat += parseFloat(latitude);
    acc.lng += parseFloat(longitude);
    return acc;
  }, { lat: 0, lng: 0 });

  return {
    lat: total.lat / validGravestones.length,
    lng: total.lng / validGravestones.length,
  };
}

const apiIsLoaded = (map, maps, gravestones, workorder_id, initiateVisit, mapSettings) => {
    const apiURL = process.env.REACT_APP_API_BASE_URL || '';
    if (!map || !maps || gravestones.length === 0) {
        console.error("Map or Maps API is not loaded or no gravestones are available");
        return;
    }

    const bounds = new maps.LatLngBounds();
    const markers = [];
    const infoWindow = new maps.InfoWindow();

    gravestones.forEach(gravestone => {
      const latValue = gravestone.latitude === 'NULL' ? null : parseFloat(gravestone.latitude);
      const lngValue = gravestone.longitude === 'NULL' ? null : parseFloat(gravestone.longitude);

      if (!latValue || !lngValue) {
        console.error("Skipping gravestone due to invalid coordinates", gravestone);
        return;
      }

      const latLng = new maps.LatLng(latValue, lngValue);
      bounds.extend(latLng);

      const marker = new maps.Marker({
        map: map,
        position: latLng,
        icon: {
          url: '/images/grave_marker.png', // Ensure this path is correct
          scaledSize: new maps.Size(mapSettings.markerScale || 40, mapSettings.markerScale || 40) // Adjust size as needed
        },
      });

      markers.push(marker);

      // Custom content for the InfoWindow
      const contentString = `
      <div style="background-color: white; color: black; border-radius: 10px; overflow: hidden; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); font-family: Arial, sans-serif; max-width: 300px; display: flex;">
        <img src="${gravestone.thumbnail ? apiURL + gravestone.thumbnail : '/images/gravestone_placeholder.jpg'}" alt="Gravestone Image" style="width: 40%; height: auto; border-top-left-radius: 10px; object-fit: cover;">
        <div style="padding: 10px; width: 60%; display: flex; flex-direction: column; justify-content: space-between;">
          <div>
            <h3 style="margin-top: 0; color: #333;">${gravestone.gravepersons_names || 'Unknown'}</h3>
            <p style="font-size: 14px; color: #555; margin: 5px 0;">
              <strong>Kirkegård:</strong> ${gravestone.graveyard_name || 'Not specified'}
            </p>
            <p style="font-size: 14px; color: #555; margin: 5px 0;">
              <strong>Sist Besøkt:</strong> ${gravestone.latest_visit_date ? new Date(gravestone.latest_visit_date).toLocaleDateString() : 'Never'}
            </p>
          </div>
          <div style="text-align: center; margin-top: 10px;">
            <button id="visitButton-${gravestone.id}" style="background-color: #007BFF; border: none; color: white; padding: 8px 16px; text-align: center; text-decoration: none; display: inline-block; font-size: 14px; border-radius: 5px; cursor: pointer;">
              Se Grav
            </button>
          </div>
        </div>
      </div>
      `;

      let isOpen = false;

      // Add click event listener for the marker to show the InfoWindow
      marker.addListener('click', () => {
        infoWindow.setContent(contentString);
        infoWindow.open(map, marker);
        isOpen = true;
        infoWindow.addListener('domready', () => {
          const button = document.getElementById(`visitButton-${gravestone.id}`);
          if (button) {
        button.addEventListener('click', () => {
          
          if (workorder_id !== '') {
            
            
            initiateVisit(gravestone.id, workorder_id);
          } else {
          window.location.href = `/gravsteiner/${gravestone.id}`;
          }
        });
          }
        });
      });

      // Add mouseover event listener for the marker to show the hover InfoWindow
      marker.addListener('mouseover', () => {
        const hoverContentString = `
        <div style="background-color: white; color: black; border-radius: 10px; overflow: hidden; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); font-family: Arial, sans-serif; max-width: 200px; padding: 5px;">
          <p style="margin: 0; font-size: 14px;">${gravestone.gravepersons_names || 'Unknown'}</p>
        </div>
        `;
        infoWindow.setContent(hoverContentString);
        infoWindow.open(map, marker);
      });

      // Add mouseout event listener to close the hover InfoWindow
      marker.addListener('mouseout', () => {
        if (!isOpen) {
          infoWindow.close();
        }
      });

      // Add closeclick event listener to prevent closing the InfoWindow on mouseout
      infoWindow.addListener('closeclick', () => {
        infoWindow.open(null, null);
        isOpen = false;
      });

      // Add click event listener to close the InfoWindow if clicked outside
      maps.event.addListener(map, 'click', () => {
        if (isOpen) {
          infoWindow.close();
          isOpen = false;
        }
      });
    });

    if (mapSettings.enableClustering) {
      new MarkerClusterer({ map, markers });
    }

    if (!bounds.isEmpty()) {
        map.fitBounds(bounds);
        map.setZoom(Math.min(map.getZoom(), 15)); // Ensure it doesn't zoom out too far
    }
};

export default GravestoneMapAll;