import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';

const Error = ({ message, retryAction }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-red-50 to-red-100">
      <div className="bg-white shadow-md border border-red-400 rounded-xl p-8 max-w-xl w-full mx-4">
        <div className="flex flex-col items-center">
          <FaExclamationTriangle className="text-red-600 mb-4" size={56} />
          <h2 className="text-2xl font-extrabold text-red-700 mb-4">Noe gikk galt</h2>
          <p className="text-gray-600 text-center leading-relaxed">
            {message || 'Vi beklager, men noe gikk galt. Prøv igjen senere.'}
          </p>
        </div>
        {retryAction && (
          <div className="flex justify-center mt-8">
            <button
              onClick={retryAction}
              className="px-6 py-3 bg-red-500 text-white font-semibold rounded-lg hover:bg-red-600 transition-transform transform hover:scale-105 focus:outline-none focus:ring focus:ring-red-300"
            >
              Prøv igjen
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Error;