// src/context/UserContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';

const UserContext = createContext();

export function useUser() {
  return useContext(UserContext);
}

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  let sessionTimeout = null; // Keep track of the timeout to clear it when needed

  useEffect(() => {
    // Initialize user from localStorage
    const accessToken = localStorage.getItem('accessToken');
    const accessTokenExpiration = localStorage.getItem('accessTokenExpiration');
    const userId = localStorage.getItem('userId');
    const userName = localStorage.getItem('userName');
    const userType = localStorage.getItem('userType');

    if (
      accessToken &&
      accessTokenExpiration &&
      Date.now() < parseInt(accessTokenExpiration) &&
      userId &&
      userName &&
      userType
    ) {
      setUser({ id: userId, name: userName, type: userType });
      startSessionTimeout(parseInt(accessTokenExpiration));
    }

    setIsAuthChecked(true);

    // Clean up on unmount
    return () => {
      if (sessionTimeout) {
        clearTimeout(sessionTimeout);
      }
    };
  }, []);

  const login = (userData, expirationTime) => {
    setUser(userData);
    if (expirationTime) {
      startSessionTimeout(expirationTime);
    } else {
      const storedExpiration = localStorage.getItem('accessTokenExpiration');
      if (storedExpiration) {
        startSessionTimeout(parseInt(storedExpiration));
      }
    }
  };

  const logout = () => {
    setUser(null);
    if (sessionTimeout) {
      clearTimeout(sessionTimeout);
    }
    localStorage.clear();
    // Removed navigate('/logginn') here
  };

  const refreshToken = async () => {
    const storedRefreshToken = localStorage.getItem('refreshToken');

    if (!storedRefreshToken) {
      logout();
      return;
    }

    try {
      const response = await axiosInstance.post(`/api/token`, { token: storedRefreshToken });
      const data = response.data;
      const newExpiration = Date.now() + 2 * 60 * 60 * 1000;

      // Update tokens and expiration
      localStorage.setItem('accessToken', data.accessToken);
      localStorage.setItem('accessTokenExpiration', newExpiration);

      if (data.refreshToken) {
        localStorage.setItem('refreshToken', data.refreshToken);
      }

      // Update user info if provided in the response
      if (data.userId && data.userName && data.userType) {
        localStorage.setItem('userId', data.userId);
        localStorage.setItem('userName', data.userName);
        localStorage.setItem('userType', data.userType);

        setUser({ id: data.userId, name: data.userName, type: data.userType });
      }

      startSessionTimeout(newExpiration);
    } catch (err) {
      if (err.response && err.response.status === 403) {
        console.error('Refresh token rejected. Logging out.');
        logout();
      } else {
        console.error('Temporary error refreshing token:', err);
        // Optionally, you could retry the refresh or notify the user
      }
    }
  };

  const startSessionTimeout = (expiration) => {
    if (sessionTimeout) {
      clearTimeout(sessionTimeout);
    }

    const timeout = expiration - Date.now() - 5 * 60 * 1000; // Refresh 5 minutes before expiration
    if (timeout > 0) {
      sessionTimeout = setTimeout(refreshToken, timeout);
    } else {
      refreshToken();
    }
  };

  return (
    <UserContext.Provider value={{ user, isAuthChecked, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};