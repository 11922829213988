import React, { useState } from "react";
import {
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  AiOutlineLike,
  AiOutlineDislike,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import Rating from "./Rating";
import ContextMenu from "./ContextMenu";
import NoDataTable from "./NoDataTable";

// Utility Functions
const getColorForDateDifference = (latestVisitDateStr, daysBetweenVisits) => {
  if (!latestVisitDateStr) return "text-gray-500";

  const today = new Date();
  const latestVisitDate = new Date(latestVisitDateStr);
  const differenceInDays = (today - latestVisitDate) / (1000 * 3600 * 24);

  if (differenceInDays <= daysBetweenVisits) return "text-green-grave";
  if (differenceInDays <= daysBetweenVisits * 2) return "text-orange-grave";
  return "text-red-500";
};

const calculateDaysSinceLastVisit = (lastVisitDate) => {
  const today = new Date();
  const lastVisit = new Date(lastVisitDate);
  return Math.floor((today - lastVisit) / (1000 * 3600 * 24));
};

// Skeleton Row Component
const SkeletonRow = ({ columns }) => (
  <tr className="animate-pulse">
    {columns.map((column, index) => (
      <td key={index} className="px-4 py-3">
        <div className="h-4 bg-gray-200 rounded w-full"></div>
      </td>
    ))}
  </tr>
);

const ReusableTable = ({
  columns,
  data,
  sort,
  onSort,
  isSelected,
  handleRowSelectionChange,
  onRowRightClick,
  isLoading, // New prop for loading state
}) => {
  const [contextMenu, setContextMenu] = useState(null);
  const apiURL = process.env.REACT_APP_API_BASE_URL || "";

  // Event Handlers
  const handleRightClick = (event, row) => {
    event.preventDefault();
    if (onRowRightClick) {
      const menuItems = onRowRightClick(row);
      if (menuItems && menuItems.length > 0) {
        setContextMenu({
          items: menuItems,
          position: { x: event.pageX, y: event.pageY },
        });
      }
    }
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  // Render Functions
  const renderStatus = (cellValue) => {
    const statusClasses = {
      completed: "text-green-grave",
      failed: "text-red-500",
      pending: "text-yellow-500",
    };
    const statusText = {
      completed: "Fullført",
      failed: "Mislyktes",
      pending: "Ventende",
    };
    return (
      <span className={statusClasses[cellValue] || "text-gray-500"}>
        {statusText[cellValue] || "Ukjent"}
      </span>
    );
  };

  const renderNames = (cellValue, column, row) => {
    const linkPath = typeof column.link === "function" ? column.link(row) : column.link;
    const names = cellValue.split(",").map((name) => name.trim());

    return column.link ? (
      <Link to={linkPath} className="text-green-grave hover:text-orange-grave underline">
        {names.map((name, index) => (
          <div key={index}>{name}</div>
        ))}
      </Link>
    ) : (
      names.map((name, index) => <div key={index}>{name}</div>)
    );
  };

  const renderSpan = (cellValue) => (
    <div className="flex flex-wrap gap-2">
      {cellValue.split(",").map((item, index) => (
        <span key={index} className="bg-orange-grave text-white rounded px-2 py-1 text-sm">
          {item.trim()}
        </span>
      ))}
    </div>
  );

  const renderImage = (cellValue, column, row, cellSecond) => {
    const cellLabel = row[column.label] || "";
    const borderStyle = cellSecond ? { border: `4px solid ${cellSecond}` } : {};
    return (
      <img
        src={`${apiURL}${cellValue}`}
        alt={cellLabel}
        className="w-16 h-16 object-cover rounded-lg shadow-md"
        style={borderStyle}
      />
    );
  };

  const renderBoolean = (cellValue) =>
    parseInt(cellValue) ? (
      <AiOutlineLike className="text-green-grave text-xl" />
    ) : (
      <AiOutlineDislike className="text-red-500 text-xl" />
    );

  const renderReverseBoolean = (cellValue) =>
    cellValue ? (
      <AiOutlineDislike className="text-red-500 text-xl" />
    ) : (
      <AiOutlineLike className="text-green-grave text-xl" />
    );

  const renderDate = (cellValue, column, row) => {
    if (!cellValue || isNaN(new Date(cellValue).getTime())) {
      return <span className="text-gray-500">Ingen dato</span>;
    }

    const formattedDate = new Date(cellValue).toLocaleDateString("nb-NO");
    const linkPath = typeof column.link === "function" ? column.link(row) : column.link;

    return column.link ? (
      <Link to={linkPath} className="text-green-grave hover:text-orange-grave underline">
        {formattedDate}
      </Link>
    ) : (
      formattedDate
    );
  };

  const renderDateTime = (cellValue, column, row) => {
    if (!cellValue || isNaN(new Date(cellValue).getTime())) {
      return <span className="text-gray-500">Ingen dato/tid</span>;
    }

    const formattedDateTime = new Date(cellValue).toLocaleString("nb-NO");
    const linkPath = typeof column.link === "function" ? column.link(row) : column.link;

    return column.link ? (
      <Link to={linkPath} className="text-green-grave hover:text-orange-grave underline">
        {formattedDateTime}
      </Link>
    ) : (
      formattedDateTime
    );
  };

  const renderRecurringDate = (cellValue, column, row) => {
    if (!cellValue || isNaN(new Date(cellValue).getTime())) {
      return <span className="text-gray-500">Ingen dato</span>;
    }

    const isRecurring = Boolean(column.is_recurring || row.is_recurring);

    const formattedDate = new Date(cellValue).toLocaleDateString("nb-NO", {
      day: "numeric",
      month: "long",
      ...(isRecurring ? {} : { year: "numeric" }),
    });

    const linkPath = typeof column.link === "function" ? column.link(row) : column.link;

    return column.link ? (
      <Link to={linkPath} className="text-green-grave hover:text-orange-grave underline">
        {formattedDate}
        {isRecurring && <span className="ml-2 text-xs font-bold text-blue-700">(Gjentakende)</span>}
      </Link>
    ) : (
      <>
        {formattedDate}
        {isRecurring && <span className="ml-2 text-xs font-bold text-blue-700">(Gjentakende)</span>}
      </>
    );
  };

  const renderDays = (cellValue, column, row, cellSecond) => {
    const daysSinceLastVisit = cellSecond ? calculateDaysSinceLastVisit(cellSecond) : null;
    const daysBetweenVisits = parseInt(cellValue, 10);
    const daysOverLimit = daysSinceLastVisit > daysBetweenVisits ? daysSinceLastVisit - daysBetweenVisits : 0;
    const colorClass = getColorForDateDifference(cellSecond, daysBetweenVisits);

    return (
      <span className={`${colorClass}`}>
        {cellSecond ? `${daysBetweenVisits}` : "Ingen Besøk"}
        {daysOverLimit > 0 ? ` (+${daysOverLimit})` : ""}
      </span>
    );
  };

  const renderRating = (cellValue, column, row) => (
    <Rating
      visit_type={row.visit_type}
      visit_id={row.visit_id}
      gravestone_id={row.gravestone_id}
      customer_id={row.customer_id}
      readOnly={true}
    />
  );

  const renderVisitLink = (cellValue, column, row) => {
    const visitDetailPath = `/besok/${row.visit_id}/detaljer/${row.visit_type === "visits" ? "1" : "0"}`;
    return (
      <Link to={visitDetailPath} className="text-green-grave underline hover:text-orange-grave">
        {row.visit_id}
      </Link>
    );
  };

  const renderToggle = (cellValue, column, row) => {
    const rowId = row.id;
    const rowIsLegacy = row.is_legacy;
    return (
      <input
        type="checkbox"
        className="form-checkbox h-5 w-5 text-green-grave"
        checked={isSelected(rowId, rowIsLegacy)}
        onChange={(e) => handleRowSelectionChange(rowId, rowIsLegacy, e.target.checked)}
      />
    );
  };

  const renderMeasurement = (cellValue) => {
    if (!cellValue) return "Nei";
    return (
      <div className="flex items-center">
        <span>{cellValue}</span>
        <span className="text-gray-500 text-sm ml-1">cm</span>
      </div>
    );
  };

  const renderDefault = (cellValue, column, row) => {
    if (column.link) {
      const linkPath = typeof column.link === "function" ? column.link(row) : column.link;
      return (
        <Link to={linkPath} className="text-green-grave hover:text-orange-grave underline">
          {cellValue}
        </Link>
      );
    }
    return cellValue;
  };

  const handlePayloadTo = (cellValue, column, row) => {
    const payload = JSON.parse(row.payload);
    return payload.to;
  };

  const renderCell = (row, column) => {
    const cellValue = row[column.accessor] || "";
    const cellSecond = row[column.second] || "";

    const renderFunctions = {
      names: renderNames,
      span: renderSpan,
      image: renderImage,
      boolean: renderBoolean,
      "reverse-boolean": renderReverseBoolean,
      date: renderDate,
      "date-time": renderDateTime,
      "recurring-date": renderRecurringDate,
      html: (cellValue) => <div dangerouslySetInnerHTML={{ __html: cellValue }} />,
      days: renderDays,
      toggle: renderToggle,
      measurement: renderMeasurement,
      rating: renderRating,
      "visit-link": renderVisitLink,
      status: renderStatus,
      "payload-to": handlePayloadTo,
      default: renderDefault,
    };

    const renderFunction = renderFunctions[column.type] || renderFunctions.default;
    const cellContent = renderFunction(cellValue, column, row, cellSecond);

    return column.onClick ? (
      <div onClick={() => column.onClick(row)} className="cursor-pointer">
        {cellContent}
      </div>
    ) : (
      cellContent
    );
  };

  // Selection Logic
  const hasToggle =
    columns.some((column) => column.type === "toggle") && typeof isSelected === "function";
  const isAllSelected = hasToggle
    ? data.every((row) => isSelected(row.id, row.is_legacy))
    : false;

  const toggleAll = hasToggle
    ? (isChecked) => {
        data.forEach((row) => handleRowSelectionChange(row.id, row.is_legacy, isChecked));
      }
    : () => {};

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white shadow rounded-lg">
        <thead className="bg-green-grave text-white">
          <tr>
            {columns.map((column) => (
              <th
                key={column.accessor}
                className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider"
                style={{ width: column.width || "auto" }}
              >
                {column.type === "toggle" ? (
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-green-500"
                    checked={isAllSelected}
                    onChange={(e) => toggleAll(e.target.checked)}
                  />
                ) : (
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => column.sortable !== false && onSort(column.accessor)}
                  >
                    {column.label}
                    {sort.field === column.accessor &&
                      (sort.order === "ASC" ? (
                        <AiOutlineArrowUp className="ml-1" />
                      ) : (
                        <AiOutlineArrowDown className="ml-1" />
                      ))}
                  </div>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-gray-700">
          {isLoading ? (
            // Render skeleton rows when loading
            [...Array(5)].map((_, index) => (
              <SkeletonRow key={index} columns={columns} />
            ))
          ) : data.length > 0 ? (
            data.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                className={`hover:bg-gray-100 ${
                  rowIndex % 2 === 0 ? "bg-gray-50" : "bg-white"
                }`}
                onContextMenu={(e) => handleRightClick(e, row)}
              >
                {columns.map((column) => (
                  <td
                    key={column.accessor}
                    className={`px-4 py-3 text-sm ${
                      column.type === "color" ? "text-white" : ""
                    }`}
                    style={{
                      backgroundColor:
                        column.type === "color" ? renderCell(row, column) : "",
                      width: column.width || "auto",
                    }}
                  >
                    {renderCell(row, column)}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length}>
                <NoDataTable message="Ingen data å vise" />
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {contextMenu && (
        <ContextMenu
          items={contextMenu.items}
          position={contextMenu.position}
          onClose={handleCloseContextMenu}
        />
      )}
    </div>
  );
};

export default ReusableTable;