import React, { useState, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import ReactDOM from 'react-dom'; // Import ReactDOM for portal
import { AuthContext } from '../context/AuthContext';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { FaChevronRight } from 'react-icons/fa'; // Icon to show submenu

const MenuItem = ({ icon, label, isOpen, to, userType, hasNotification, children }) => {
  const Icon = icon;
  const { user } = useContext(AuthContext);
  const [isHovered, setIsHovered] = useState(false);
  const [isSubmenuHovered, setIsSubmenuHovered] = useState(false); // Track hover state of submenu
  const [submenuPosition, setSubmenuPosition] = useState({ top: 0, left: 0 }); // Track submenu position
  const itemRef = useRef(null); // Ref for the hovered menu item

  const labelVariants = {
    opened: {
      opacity: 1,
      x: 0,
      transition: {
        type: 'spring',
        stiffness: 260,
        damping: 20,
      },
    },
    closed: {
      opacity: 0,
      x: -20,
      transition: {
        type: 'spring',
        stiffness: 260,
        damping: 20,
      },
    },
  };

  const shouldRender = !userType || user?.type === userType;

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (itemRef.current) {
      // Get the bounding rectangle of the menu item
      const rect = itemRef.current.getBoundingClientRect();
      setSubmenuPosition({
        top: rect.top, // Position the submenu at the same vertical level
        left: rect.right, // Position it to the right of the menu item
      });
    }
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      // Delay the closing to prevent submenu from disappearing instantly
      if (!isSubmenuHovered) {
        setIsHovered(false);
      }
    }, 100); // Short delay to give time for submenu hover
  };

  const handleSubmenuMouseEnter = () => {
    setIsSubmenuHovered(true);
  };

  const handleSubmenuMouseLeave = () => {
    setIsSubmenuHovered(false);
    setTimeout(() => {
      if (!isHovered) {
        setIsHovered(false);
      }
    }, 100); // Delay to ensure submenu doesn't disappear too quickly
  };

  return shouldRender ? (
    <>
      <li
        ref={itemRef} // Attach the ref to the menu item
        className={`relative flex items-center px-4 py-2 text-white hover:text-black cursor-pointer hover:bg-green-grave transition-colors duration-200 ${
          isOpen ? 'justify-start' : 'justify-center'
        } w-full`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ position: 'relative' }}
      >
        {/* Main item link */}
        <Link to={to} className={`flex w-full h-full items-center ${!isOpen && 'justify-center'}`} data-tooltip-id={label}>
          <Icon className={`text-xl flex-shrink-0 ${!isOpen ? 'mx-auto' : ''}`} />
          <motion.span
            variants={labelVariants}
            initial="closed"
            animate={isOpen ? 'opened' : 'closed'}
            className="ml-4"
            style={{ display: isOpen ? 'block' : 'none' }}
          >
            {label}
          </motion.span>
          {children && <FaChevronRight className="ml-auto" />} {/* Show submenu indicator */}
          {hasNotification && (
            <span className="absolute top-1 right-1 bg-red-600 h-2 w-2 rounded-full"></span>
          )}
        </Link>

        {/* Tooltip for when the sidebar is closed */}
        {!isOpen && (
          <ReactTooltip
            id={label}
            place="right"
            effect="solid"
            offset={{ left: 10 }}
            className="sidebar-tooltip"
          >
            {label}
          </ReactTooltip>
        )}
      </li>

      {/* Submenu: Only shown on hover if children exist */}
      {(isHovered || isSubmenuHovered) && children && ReactDOM.createPortal(
  <ul
    className="absolute py-2 rounded-r-lg w-48 bg-gray-700 text-white shadow-lg z-50"
    style={{
      position: 'fixed',
      top: submenuPosition.top,
      left: submenuPosition.left,
      zIndex: 999,
    }}
    onMouseEnter={handleSubmenuMouseEnter}
    onMouseLeave={handleSubmenuMouseLeave}
  >
    {children.map((child, index) => (
      <Link to={child.to} key={index}>
        <li className="py-1 px-2 hover:bg-green-grave hover:text-black">
          {child.label}
        </li>
      </Link>
    ))}
  </ul>,
  document.body
)}

    </>
  ) : null;
};

export default MenuItem;