// UserProfile.js

import React, { useEffect, useState, useContext } from "react";
import axiosInstance from "../../utils/axiosInstance";
import { useParams, Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useUsers } from "../../hooks";
import { useLogs } from "../../hooks/useLogs";
import {
  FaUserCircle,
  FaEnvelope,
  FaCalendarAlt,
  FaCalendarCheck,
  FaEdit,
  FaSignOutAlt,
  FaSignInAlt,
  FaPencilAlt,
  FaCog,
  FaExclamationTriangle,
  FaCogs,
  FaSignOutAlt as FaLogout,
} from "react-icons/fa";

const UserProfile = () => {
  const { id } = useParams();
  const [personalProfile, setPersonalProfile] = useState(false);
  const [visibleLogs, setVisibleLogs] = useState(10);
  const [filter, setFilter] = useState("");

  const { profile, fetchUserById } = useUsers();
  const { logs, fetchLogsByUserId, isLoading: logsLoading } = useLogs();
  const { user } = useContext(AuthContext);

  const [worklogs, setWorklogs] = useState([]); // State for worklogs

  const apiURL = process.env.REACT_APP_API_BASE_URL || "";

  useEffect(() => {
    fetchUserById(id);
    fetchLogsByUserId(id);
    if (user && parseInt(user.id) === parseInt(id)) {
      setPersonalProfile(true);
    }

    // Fetch worklogs for the user
    const fetchWorklogs = async () => {
      try {
        const response = await axiosInstance.get(`/api/worklogs?user_ids=${id}`);
        setWorklogs(response.data.worklogs);
      } catch (error) {
        console.error("Failed to fetch worklogs:", error);
      }
    };

    fetchWorklogs();
  }, [id]);

  const getIconForLog = (type) => {
    switch (type) {
      case "login":
        return <FaSignInAlt className="text-green-grave mr-3 inline-block align-middle" />;
      case "logout":
        return <FaLogout className="text-red-500 mr-3 inline-block align-middle" />;
      case "edit":
        return <FaPencilAlt className="text-orange-500 mr-3 inline-block align-middle" />;
      case "system":
        return <FaCog className="text-gray-400 mr-3 inline-block align-middle" />;
      case "error":
        return <FaExclamationTriangle className="text-red-500 mr-3 inline-block align-middle" />;
      case "action":
        return <FaCogs className="text-blue-500 mr-3 inline-block align-middle" />;
      default:
        return <FaUserCircle className="text-gray-400 mr-3 inline-block align-middle" />;
    }
  };

  const filteredLogs = logs.filter((log) =>
    log.message.toLowerCase().includes(filter.toLowerCase())
  );

  const handleShowMore = () => {
    setVisibleLogs((prev) => prev + 10);
  };

  const calculateWorkHours = (startTime, endTime) => {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const diffInMs = end - start;
    const diffInHours = diffInMs / (1000 * 60 * 60);
    return diffInHours.toFixed(2);
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4 lg:p-8">
      <div className="w-full grid grid-cols-1 lg:grid-cols-12 gap-8">
        {/* Sidebar Section */}
        <div className="bg-white shadow-xl rounded-lg p-6 lg:col-span-3">
          <div className="flex flex-col items-center">
            {profile.picture ? (
              <img
                src={apiURL + "/" + profile.picture}
                alt={`${profile.name}'s profile`}
                className="w-32 h-32 rounded-full object-cover shadow-md mb-4"
              />
            ) : (
              <FaUserCircle className="text-gray-400 text-8xl mb-4" />
            )}
            <h3 className="text-xl font-bold text-gray-800 mb-1 text-center">{profile.name}</h3>
            <p className="text-green-grave text-md font-medium text-center">
              {profile.type === "worker" ? "Arbeider" : "Admin"}
            </p>
            <div className="mt-6 space-y-4 w-full">
              <div className="flex items-center justify-center">
                <FaEnvelope className="text-green-grave mr-2 text-lg" />
                <span className="text-gray-800 text-md font-medium text-center">
                  {profile.email}
                </span>
              </div>
              <div className="flex items-center justify-center">
                <FaCalendarAlt className="text-green-grave mr-2 text-lg" />
                <span className="text-gray-800 text-md font-medium text-center">
                  Opprettet: {new Date(profile.created_at).toLocaleString("nb-NO")}
                </span>
              </div>
              <div className="flex items-center justify-center">
                <FaCalendarCheck className="text-green-grave mr-2 text-lg" />
                <span className="text-gray-800 text-md font-medium text-center">
                  Sist Innlogget: {new Date(profile.last_login).toLocaleString("nb-NO")}
                </span>
              </div>
            </div>
            <div className="flex mt-6 w-full justify-around">
              <Link
                to={`/brukere/${id}/endre`}
                className="flex items-center justify-center px-4 py-2 bg-green-grave hover:bg-green-800 text-white rounded-lg transition duration-200 ease-in-out"
              >
                <FaEdit className="mr-2" /> Endre
              </Link>
              {personalProfile && (
                <Link
                  to="/loggut"
                  className="flex items-center justify-center px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-lg transition duration-200 ease-in-out"
                >
                  <FaSignOutAlt className="mr-2" /> Logg Ut
                </Link>
              )}
            </div>
          </div>
        </div>

        {/* Main Content Section */}
        <div className="lg:col-span-9 flex flex-col space-y-8">
          {/* User Activity Section */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <h3 className="text-2xl font-bold text-gray-800 mb-4 text-center lg:text-left">
              Brukeraktivitet
            </h3>
            <input
              type="text"
              placeholder="Søk i logger..."
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="w-full p-3 mb-6 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-grave"
            />
            <div className="bg-gray-50 p-4 max-h-96 rounded-lg border border-gray-200 overflow-y-auto">
              {logsLoading ? (
                <div className="flex justify-center items-center">
                  <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-b-4 border-green-grave"></div>
                </div>
              ) : filteredLogs.length > 0 ? (
                <ul className="space-y-4">
                  {filteredLogs.slice(0, visibleLogs).map((log) => (
                    <li
                      key={log.id}
                      className="flex items-start p-3 bg-white rounded-lg shadow-md hover:bg-gray-100 transition duration-200 ease-in-out"
                    >
                      {getIconForLog(log.type)}
                      <div>
                        <span className="font-semibold text-gray-900 mr-2">
                          {new Date(log.created_at).toLocaleString("nb-NO")}:
                        </span>
                        <span className="text-gray-700">{log.message}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-gray-500">Ingen nylige aktiviteter.</p>
              )}
            </div>
            {visibleLogs < filteredLogs.length && (
              <button
                onClick={handleShowMore}
                className="text-green-grave mt-4 text-lg underline hover:text-green-800"
              >
                Vis mer logger
              </button>
            )}
          </div>

          {/* Worklogs Section */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <h3 className="text-2xl font-bold text-gray-800 mb-4 text-center lg:text-left">
              Arbeidstimer
            </h3>
            <div className="overflow-x-auto">
              {worklogs.length > 0 ? (
                <table className="min-w-full bg-white shadow rounded-lg">
                  <thead className="bg-green-grave text-white">
                    <tr>
                      <th className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">
                        Starttid
                      </th>
                      <th className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">
                        Slutttid
                      </th>
                      <th className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">
                        Antall Timer
                      </th>
                      <th className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">
                        Kommentar ved innlogging
                      </th>
                      <th className="px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">
                        Kommentar ved utlogging
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-700">
                    {worklogs.map((worklog, index) => (
                      <tr
                        key={index}
                        className={`hover:bg-gray-100 ${
                          index % 2 === 0 ? "bg-gray-50" : "bg-white"
                        }`}
                      >
                        <td className="px-4 py-3 text-sm">
                          {new Date(worklog.start_time).toLocaleString("nb-NO")}
                        </td>
                        <td className="px-4 py-3 text-sm">
                          {worklog.end_time
                            ? new Date(worklog.end_time).toLocaleString("nb-NO")
                            : "Pågående"}
                        </td>
                        <td className="px-4 py-3 text-sm">
                          {worklog.end_time
                            ? `${calculateWorkHours(worklog.start_time, worklog.end_time)} timer`
                            : "N/A"}
                        </td>
                        <td className="px-4 py-3 text-sm">
                          {worklog.login_comment || "Ingen kommentar"}
                        </td>
                        <td className="px-4 py-3 text-sm">
                          {worklog.logout_comment || "Ingen kommentar"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
              <p className="text-gray-600">Ingen arbeidstimer funnet.</p>
            </div>
              )}
            </div>
          </div>

          {/* Comments Section */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <h3 className="text-2xl font-bold text-gray-800 mb-4 text-center lg:text-left">
              Kommentarer
            </h3>
            <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
              <p className="text-gray-600">Ingen kommentarer.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;