import React, { useState, useEffect } from "react";
import Topbar from "../../components/Topbar";
import Toolbar from "../../components/Toolbar";
import ReusableTable from "../../components/ReusableTable";
import Spinner from "../../components/Spinner";
import ReusableHeader from "../../components/ReusableHeader";
import { useActivities } from "../../hooks";

function Activities() {
  const [error, setError] = useState(null);
  const [sort, setSort] = useState("created_at");
  const [order, setOrder] = useState("DESC");

  const { fetchActivities, isLoading, activities } = useActivities();

  const apiURL = process.env.REACT_APP_API_BASE_URL || "";

  useEffect(() => {
    fetchActivities();
  }, []);

  const handleSort = (field) => {
    if (sort === field) {
      setOrder(order === "ASC" ? "DESC" : "ASC");
    } else {
      setSort(field);
      setOrder("ASC");
    }
  };

  // Adjust columns as per your gravestone data structure
  const columns = [
    { label: "ID", accessor: "id", width: "50px" },
    {
      label: "Kode",
      accessor: "code",
      link: (row) => `/aktiviteter/${row.id}`,
      width: "100px",
    },
    { label: "Navn", accessor: "name", width: "150px" },
    {
      label: "Opprettet",
      accessor: "created_at",
      type: "date-time",
      width: "200px",
    },
    {
      label: "Oppdatert",
      accessor: "updated_at",
      type: "date-time",
      width: "200px",
    },
    // Add more columns as needed
  ];

  if (error)
    return <div className="text-red-500 text-center">Error: {error}</div>;

  return (
    <div className="overflow-x-auto p-4 relative">
      <Topbar>
        <ReusableHeader title="Aktiviteter" linkTo="/aktiviteter/ny" />
      </Topbar>
      <ReusableTable
        columns={columns}
        data={activities}
        sort={{ field: sort, order: order }}
        onSort={handleSort}
        isLoading={isLoading}
      />
    </div>
  );
}

export default Activities;
