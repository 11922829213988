import React, { useState, useEffect } from 'react';
import {
  FaPlus,
  FaBirthdayCake,
  FaHeart,
  FaGift,
  FaUsers,
  FaQuestionCircle,
  FaHeartBroken,
} from 'react-icons/fa';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import ReusableSubheader from '../ReusableSubheader';
import SpecialDayModal from './SpecialDayModal';
import SpecialDaysList from './SpecialDaysList';
import useSpecialDays from '../../hooks/useSpecialDays';

const specialDayIcons = {
  Fødselsdag: FaBirthdayCake,
  Dødsdag: FaHeartBroken,
  Bryllupsdag: FaHeart,
  'Personlige milepæler': FaGift,
  'Familiegjenforeningsdatoer': FaUsers,
  Annet: FaQuestionCircle,
};

const SpecialDaysManager = ({ gravestoneId, showHeader = true }) => {
  const {
    specialDays,
    loading,
    error,
    fetchSpecialDaysByGravestone,
    createSpecialDay,
    deleteSpecialDay,
    setActiveSpecialDay,
  } = useSpecialDays();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newSpecialDay, setNewSpecialDay] = useState({
    date: '',
    description: '',
    is_recurring: false,
  });

  useEffect(() => {
    if (gravestoneId) fetchSpecialDaysByGravestone(gravestoneId);
  }, [gravestoneId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewSpecialDay((prevDay) => ({
      ...prevDay,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleAddSpecialDay = async (newDay) => {
    try {
      await createSpecialDay({ ...newDay, gravestone_id: gravestoneId });
      setNewSpecialDay({ date: '', description: '', is_recurring: false });
      setModalIsOpen(false);
    } catch (err) {
      console.error('Error adding special day:', err);
    }
  };

  const handleDeleteSpecialDay = async (id) => {
    try {
      await deleteSpecialDay(id);
    } catch (err) {
      console.error('Error deleting special day:', err);
    }
  };

  const handleSetActiveSpecialDay = async (id) => {
    try {
      await setActiveSpecialDay(id, gravestoneId);
    } catch (err) {
      console.error('Error setting active special day:', err);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="flex flex-col items-center">
          <AiOutlineLoading3Quarters className="animate-spin text-green-grave text-4xl mb-2" />
          <p className="text-gray-700 text-lg">Laster spesielle dager...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <p className="text-red-500 text-lg">{error}</p>
      </div>
    );
  }

  return (
    <div className="mt-6">
      {showHeader && (
        <ReusableSubheader
          title="Friske Blomster"
          info="Dersom du har bestilt friske blomster på gravstedet angir dette dagen hvor vi setter ut blomsterbukett på gravstedet."
        />
      )}
      <button
        onClick={() => setModalIsOpen(true)}
        className="w-full bg-green-grave text-white px-4 py-2 rounded hover:bg-green-600 transition mb-6"
      >
        <FaPlus className="mr-2 inline-block" /> Legg til Spesiell Dag
      </button>

      <SpecialDaysList
        specialDays={specialDays}
        specialDayIcons={specialDayIcons}
        handleSetActiveSpecialDay={handleSetActiveSpecialDay}
        handleDeleteSpecialDay={handleDeleteSpecialDay}
      />

      <SpecialDayModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        newSpecialDay={newSpecialDay}
        handleChange={handleChange}
        handleAddSpecialDay={handleAddSpecialDay}
        specialDayIcons={specialDayIcons}
      />
    </div>
  );
};

export default SpecialDaysManager;