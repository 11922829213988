import React, { useEffect, useState } from "react";
import Topbar from "../../components/Topbar";
import Toolbar from "../../components/Toolbar";
import ReusableTable from "../../components/ReusableTable";
import Spinner from "../../components/Spinner";
import ReusableHeader from "../../components/ReusableHeader";
import { useProducts } from "../../hooks";

function Products() {
  const { products, isLoading, error, fetchProducts } = useProducts();
  const [sort, setSort] = useState("created_at");
  const [order, setOrder] = useState("DESC");

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const handleSort = (field) => {
    if (sort === field) {
      setOrder(order === "ASC" ? "DESC" : "ASC");
    } else {
      setSort(field);
      setOrder("ASC");
    }
  };

  const columns = [
    {
      label: "ID",
      accessor: "id",
      link: (row) => `/produkter/${row.id}`,
      width: "50px",
    },
    { label: "Navn", accessor: "name", width: "150px" },
    { label: "Beskrivelse", accessor: "description", width: "250px" },
    { label: "Periode Start", accessor: "period_start", width: "150px" },
    { label: "Periode Slutt", accessor: "period_end", width: "150px" },
    { label: "Farge", accessor: "color", type: "color", width: "100px" },
    { label: "Aktiv?", accessor: "active", type: "boolean", width: "100px" },
  ];

  if (error)
    return <div className="text-red-500 text-center">Error: {error}</div>;

  return (
    <div className="overflow-x-auto p-4 relative">
      <Topbar>
        <ReusableHeader title="Produkter" linkTo="/produkter/ny" />
      </Topbar>
      <ReusableTable
        columns={columns}
        data={products}
        sort={{ field: sort, order: order }}
        onSort={handleSort}
        isLoading={isLoading}
      />
    </div>
  );
}

export default Products;
