import React, { useState, useEffect, useMemo } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useAdminView } from "./context/AdminViewContext";
import LoadingScreen from "./components/LoadingScreen";
import ProtectedRoute from "./components/ProtectedRoute";
import ProtectedRouteCustomer from "./components/ProtectedRouteCustomer";
import UserLayout from "./layouts/UserLayout";
import CustomerLayout from "./layouts/CustomerLayout";
import AdminViewCustomerLayout from "./layouts/AdminViewCustomerLayout";
import LayoutWrapper from "./layouts/LayoutWrapper";
import CustomerDashboard from "./pages/customer-pages/CustomerDashboard";
import CustomerVisits from "./pages/customer-pages/CustomerVisits";
import CustomerVisitPage from "./pages/customer-pages/CustomerVisitPage";
import CustomerGravestones from "./pages/customer-pages/CustomerGravestones";
import CustomerGravestoneProfile from "./pages/customer-pages/CustomerGravestoneProfile";
import CustomerProfile from "./pages/customer-pages/CustomerProfile";
import CustomerLogOut from "./pages/customer-pages/CustomerLogOut";

import Dashboard from "./pages/Dashboard";
import Customers from "./pages/customers/Customers";
import AddCustomer from "./pages/customers/AddCustomer";
import CustomerDetails from "./pages/customers/CustomerDetails";
import CustomerEdit from "./pages/customers/CustomerEdit";
import Graveyards from "./pages/graveyards/Graveyards";
import AddGraveyard from "./pages/graveyards/AddGraveyard";
import GraveyardProfile from "./pages/graveyards/GraveyardProfile";
import GraveyardProfileMobile from "./pages/graveyards/GraveyardProfileMobile";
import EditGraveyard from "./pages/graveyards/EditGraveyard";
import Gravestones from "./pages/gravestones/Gravestones";
import GravestoneProfile from "./pages/gravestones/GravestoneProfile";
import AddGravestone from "./pages/gravestones/AddGravestone";
import EditGravestone from "./pages/gravestones/EditGravestone";
import Gravepersons from "./pages/gravestones/Gravepersons";
import AddGraveperson from "./pages/gravestones/AddGraveperson";
import GravepersonDetail from "./pages/gravestones/GravepersonDetail";
import Workorders from "./pages/workorders/Workorders";
import WorkOrderForm from "./pages/workorders/WorkorderForm";
import WorkordersUser from "./pages/workorders/WorkordersUser";
import WorkOrderDetailsPage from "./pages/workorders/WorkOrderDetailsPage";
import Visits from "./pages/visits/Visits";
import VisitsThumbsDown from "./pages/visits/VisitsThumbsDown";
import VisitCustomerPage from "./pages/visits/VisitCustomerPage";
import VisitEditPage from "./pages/visits/VisitEditPage";
import VisitDetailPage from "./pages/visits/VisitDetailPage";
import LegacyVisitDetailPage from "./pages/visits/LegacyVisitDetailPage";
import VisitComments from "./pages/visits/VisitComments";
import Users from "./pages/users/Users";
import UserProfile from "./pages/users/UserProfile";
import CreateUser from "./pages/users/CreateUser";
import EditUser from "./pages/users/EditUser";
import UserProfileEdit from "./pages/users/UserProfileEdit";
import SmsLogs from "./pages/Logs/SmsLogs";
import EmailLogs from "./pages/Logs/EmailLogs";
import Logs from "./pages/Logs/Logs";
import Templates from "./pages/templates/Templates";
import CreateEmailTemplate from "./pages/templates/CreateEmailTemplate";
import CreateSMSTemplate from "./pages/templates/CreateSMSTemplate";
import EditEmailTemplate from "./pages/templates/EditEmailTemplate";
import EditSMSTemplate from "./pages/templates/EditSMSTemplate";
import Activities from "./pages/activities/Activities";
import AddActivity from "./pages/activities/AddActivity";
import ViewActivity from "./pages/activities/ViewActivity";
import EditActivity from "./pages/activities/EditActivity";
import Products from "./pages/products/Products";
import AddProduct from "./pages/products/AddProduct";
import ViewProduct from "./pages/products/ViewProduct";
import Departments from "./pages/departments/Departments";
import EditDepartment from "./pages/departments/EditDepartment";
import CreateDepartment from "./pages/departments/CreateDepartment";
import Login from "./pages/Login";
import CustomerLogin from "./pages/CustomerLogin";
import DownloadImagesPage from "./pages/tools/DownloadImagesPage";
import Slideshow from "./pages/Slideshow";
import Logout from "./pages/Logout";
import Settings from "./pages/settings/Settings";
import Worklogs from "./pages/worklogs/Worklogs";
import FollowUps from "./pages/followUps/FollowUps";
import SpecialDays from "./pages/specialDays/SpecialDays";

import GraveyardsMobile from "./pages/graveyards/GraveyardsMobile";
import GravestonesMobile from "./pages/gravestones/GravestonesMobile";
import GravestonesMapMobile from "./pages/gravestones/GravestoneMapMobile";
import MyVisits from "./pages/visits/MyVisits";

import UserGuidePage from "./pages/UserGuidePage";

const AppContent = () => {

  return (
    <Routes>
      <Route path="/logginn" element={<Login />} />
      <Route path="/kunde/logginn" element={<CustomerLogin />} />
      <Route path="/ditt-besok/:visitId" element={<VisitCustomerPage />} />
      <Route path="/last-ned-bilder" element={<DownloadImagesPage />} />
      <Route path="/slideshow" element={<Slideshow />} />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <UserLayout>
              <Dashboard />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/kunder"
        element={
          <ProtectedRoute>
            <UserLayout>
              <Customers />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/kunder/ny"
        element={
          <ProtectedRoute>
            <UserLayout>
              <AddCustomer />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/kunder/:id"
        element={
          <ProtectedRoute>
            <UserLayout>
              <CustomerDetails />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/kunder/:id/endre"
        element={
          <ProtectedRoute>
            <UserLayout>
              <CustomerEdit />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/kirkegarder"
        element={
          <ProtectedRoute>
            <UserLayout>
              <Graveyards />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/kirkegarder/ny"
        element={
          <ProtectedRoute>
            <UserLayout>
              <AddGraveyard />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/kirkegarder/:id"
        element={
          <ProtectedRoute>
            <UserLayout>
              <GraveyardProfile />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/kirkegarder/:id/endre"
        element={
          <ProtectedRoute>
            <UserLayout>
              <EditGraveyard />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/gravsteiner"
        element={
          <ProtectedRoute>
            <UserLayout>
              <Gravestones />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/gravsteiner/ny/:customerId"
        element={
          <ProtectedRoute>
            <UserLayout>
              <AddGravestone />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/gravsteiner/:id"
        element={
          <ProtectedRoute>
            <UserLayout>
              <GravestoneProfile />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/gravsteiner/:id/endre"
        element={
          <ProtectedRoute>
            <UserLayout>
              <EditGravestone />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/gravpersoner"
        element={
          <ProtectedRoute>
            <UserLayout>
              <Gravepersons />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/gravpersoner/:id"
        element={
          <ProtectedRoute>
            <UserLayout>
              <GravepersonDetail />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/gravpersoner/ny/:gravestoneId"
        element={
          <ProtectedRoute>
            <UserLayout>
              <AddGraveperson />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/arbeidslister"
        element={
          <ProtectedRoute>
            <UserLayout>
              <Workorders />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/arbeidslister/ny"
        element={
          <ProtectedRoute>
            <UserLayout>
              <WorkOrderForm />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/arbeidslister/:id"
        element={
          <ProtectedRoute>
            <UserLayout>
              <WorkOrderDetailsPage />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/mine-arbeidslister"
        element={
          <ProtectedRoute>
            <UserLayout>
              <WorkordersUser />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/besok"
        element={
          <ProtectedRoute>
            <UserLayout>
              <Visits />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/besok/tommel-ned"
        element={
          <ProtectedRoute>
            <UserLayout>
              <VisitsThumbsDown />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/besok/:visitId/detaljer/:isLegacy"
        element={
          <ProtectedRoute>
            <UserLayout>
              <VisitDetailPage />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/besok/:visitId/legacy"
        element={
          <ProtectedRoute>
            <UserLayout>
              <LegacyVisitDetailPage />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/besok/:visitId/endre"
        element={
          <ProtectedRoute>
            <UserLayout>
              <VisitEditPage />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/tilbakemeldinger"
        element={
          <ProtectedRoute>
            <UserLayout>
              <VisitComments />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/brukere"
        element={
          <ProtectedRoute>
            <UserLayout>
              <Users />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/brukere/ny"
        element={
          <ProtectedRoute>
            <UserLayout>
              <CreateUser />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/brukere/:id"
        element={
          <ProtectedRoute>
            <UserLayout>
              <UserProfile />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/brukere/:id/endre"
        element={
          <ProtectedRoute>
            <UserLayout>
              <EditUser />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/profil/:id/endre"
        element={
          <ProtectedRoute>
            <UserLayout>
              <UserProfileEdit />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/sms-logger"
        element={
          <ProtectedRoute>
            <UserLayout>
              <SmsLogs />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/epost-logger"
        element={
          <ProtectedRoute>
            <UserLayout>
              <EmailLogs />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/logger"
        element={
          <ProtectedRoute>
            <UserLayout>
              <Logs />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/maler"
        element={
          <ProtectedRoute>
            <UserLayout>
              <Templates />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/maler/ny/epost"
        element={
          <ProtectedRoute>
            <UserLayout>
              <CreateEmailTemplate />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/maler/ny/sms"
        element={
          <ProtectedRoute>
            <UserLayout>
              <CreateSMSTemplate />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/maler/:id/epost"
        element={
          <ProtectedRoute>
            <UserLayout>
              <EditEmailTemplate />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/maler/:id/sms"
        element={
          <ProtectedRoute>
            <UserLayout>
              <EditSMSTemplate />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/aktiviteter"
        element={
          <ProtectedRoute>
            <UserLayout>
              <Activities />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/aktiviteter/ny"
        element={
          <ProtectedRoute>
            <UserLayout>
              <AddActivity />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/aktiviteter/:id"
        element={
          <ProtectedRoute>
            <UserLayout>
              <ViewActivity />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/aktiviteter/:id/endre"
        element={
          <ProtectedRoute>
            <UserLayout>
              <EditActivity />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/produkter"
        element={
          <ProtectedRoute>
            <UserLayout>
              <Products />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/produkter/ny"
        element={
          <ProtectedRoute>
            <UserLayout>
              <AddProduct />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/produkter/:id"
        element={
          <ProtectedRoute>
            <UserLayout>
              <ViewProduct />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/avdelinger"
        element={
          <ProtectedRoute>
            <UserLayout>
              <Departments />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/avdelinger/ny"
        element={
          <ProtectedRoute>
            <UserLayout>
              <CreateDepartment />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/avdelinger/:id/endre"
        element={
          <ProtectedRoute>
            <UserLayout>
              <EditDepartment />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/innstillinger"
        element={
          <ProtectedRoute>
            <UserLayout>
              <Settings />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/arbeidslogger"
        element={
          <ProtectedRoute>
            <UserLayout>
              <Worklogs />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/oppfolging"
        element={
          <ProtectedRoute>
            <UserLayout>
              <FollowUps />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/spesialdager"
        element={
          <ProtectedRoute>
            <UserLayout>
              <SpecialDays />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route path="/loggut" element={<Logout />} />

      <Route
        path="/mine-besok"
        element={
          <ProtectedRoute>
            <UserLayout>
              <MyVisits />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/brukerveiledning"
        element={
          <ProtectedRoute>
            <UserLayout>
              <UserGuidePage />
            </UserLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/kunde"
        element={
          <ProtectedRouteCustomer
            element={<LayoutWrapper element={<CustomerDashboard />} />}
          />
        }
      />
      <Route
        path="/kunde/besok"
        element={
          <ProtectedRouteCustomer
            element={<LayoutWrapper element={<CustomerVisits />} />}
          />
        }
      />
      <Route
        path="/kunde/besok/gravstein/:gravestoneId"
        element={
          <ProtectedRouteCustomer
            element={<LayoutWrapper element={<CustomerVisits />} />}
          />
        }
      />
      <Route
        path="/kunde/besok/:visitId/:isLegacy"
        element={
          <ProtectedRouteCustomer
            element={<LayoutWrapper element={<CustomerVisitPage />} />}
          />
        }
      />
      <Route
        path="/kunde/gravsteiner"
        element={
          <ProtectedRouteCustomer
            element={<LayoutWrapper element={<CustomerGravestones />} />}
          />
        }
      />
      <Route
        path="/kunde/gravstein/:id"
        element={
          <ProtectedRouteCustomer
            element={<LayoutWrapper element={<CustomerGravestoneProfile />} />}
          />
        }
      />
      <Route
        path="/kunde/profil"
        element={
          <ProtectedRouteCustomer
            element={<LayoutWrapper element={<CustomerProfile />} />}
          />
        }
      />
      <Route path="/kunde/loggut" element={<CustomerLogOut />} />
      {/* Add other routes as needed */}
    </Routes>
  );
};

export default AppContent;