import React, { useState, useEffect, useContext } from "react";
import Topbar from "../../components/Topbar";
import Toolbar from "../../components/Toolbar";
import ReusableTable from "../../components/ReusableTable";
import Pagination from "../../components/Pagination";
import Spinner from "../../components/Spinner";
import ReusableSearch from "../../components/ReusableSearch";
import ReusableLimit from "../../components/ReusableLimit";
import PageLayout from "../../layouts/PageLayout";
import ReusableHeaderV2 from "../../components/ReusableHeaderV2";
import ReusableDatePicker from "../../components/ReusableDatePicker";
import Modal from "../../components/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLogs } from "../../hooks/useLogs";
import { AuthContext } from "../../context/AuthContext";
import { FaRegCalendarCheck } from 'react-icons/fa';
import { MdEmail, MdTextsms } from 'react-icons/md';

function Visits() {
  const [visits, setVisits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("visit_date");
  const [order, setOrder] = useState("DESC");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [from_date, setFromDate] = useState("");
  const [to_date, setToDate] = useState("");
  const [filter_send_sms, setFilterSendSms] = useState("");
  const [filter_send_email, setFilterSendEmail] = useState("");
  const [totalPages, setTotalPages] = useState(0);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [selectedDateTime, setSelectedDateTime] = useState(null);

  const { createLog } = useLogs();
  const { user } = useContext(AuthContext);

  const [templates, setTemplates] = useState({ email: [], sms: [] });

  const [selectedRows, setSelectedRows] = useState([]);

  const apiURL = process.env.REACT_APP_API_BASE_URL || "";

  // Event handlers remain within the parent component
  const handleSearchChange = (e) => setSearch(e.target.value);
  const handleLimitChange = (e) => setLimit(e.target.value);
  const handleFromDateChange = (date) => setFromDate(date);
  const handleToDateChange = (date) => setToDate(date);

  useEffect(() => {
    const fetchVisits = async () => {
      setIsLoading(true);
      try {
        const queryParams = new URLSearchParams({
          search,
          sort,
          order,
          page,
          limit,
          from_date: from_date ? from_date.toISOString() : "",
          to_date: to_date ? to_date.toISOString() : "",
          filter_send_email,
          filter_send_sms,
          filter_thumb_down: 0,
        }).toString();
        from_date && console.log(from_date.toISOString());
        to_date && console.log(to_date.toISOString());
        const response = await fetch(`${apiURL}/api/visits?${queryParams}`);
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
        const data = await response.json();
        setVisits(data.visits);
        setTotalPages(data.totalPages);
        setIsLoading(false);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVisits();
  }, [search, sort, order, page, limit, from_date, to_date, filter_send_email, filter_send_sms]);

  useEffect(() => {
    const fetchTemplates = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${apiURL}/api/templates/getAll`);
        if (!response.ok) {
          throw new Error("Failed to fetch templates");
        }
        const data = await response.json();
        setTemplates(data);
      } catch (error) {
        console.error("Error fetching templates:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTemplates();
  }, []);

  const handleSort = (field) => {
    if (sort === field) {
      setOrder(order === "ASC" ? "DESC" : "ASC");
    } else {
      setSort(field);
      setOrder("ASC");
    }
  };

  const columns = [
    { accessor: "id", type: "toggle", width: '50px' },
    { label: "Besøkt", accessor: "visit_date", type: "date", link: (row) => `/besok/${row.id}/detaljer/${row.is_legacy}`, width: '150px' },
    {
      label: "Gravstein",
      accessor: "gravepersons",
      type: "names",
      link: (row) => `/gravsteiner/${row.gravestone_id}`,
      width: '200px'
    },
    {
      label: "Kirkegård",
      accessor: "graveyard_name",
      link: (row) => `/kirkegarder/${row.graveyard_id}`,
      width: '150px'
    },
    { label: "Aktiviteter", accessor: "activities", type: "span", width: '200px' },
    {
      label: "Arbeider",
      accessor: "user_name",
      link: (row) => `/brukere/${row.user_id}`,
      width: '150px'
    },
    { label: "Send E-post", accessor: "send_email", type: "boolean", width: '100px' },
    { label: "Send SMS", accessor: "send_sms", type: "boolean", width: '100px' },
    { label: "Legacy?", accessor: "is_legacy", type: "boolean", width: '100px' },
    { label: "Godkjent?", accessor: "thumb_down", type: "reverse-boolean", width: '100px' }
  ];

  const handleRowSelectionChange = (rowId, rowIsLegacy, isSelected) => {
    console.log(`Row ${rowId} is ${isSelected ? "selected" : "deselected"} (isLegacy: ${rowIsLegacy})`);
    setSelectedRows((prevSelectedRows) => {
        if (isSelected) {
            // Add the new selection
            return [...prevSelectedRows, { rowId, rowIsLegacy }];
        } else {
            // Remove the selection
            return prevSelectedRows.filter(
                (row) => row.rowId !== rowId || row.rowIsLegacy !== rowIsLegacy
            );
        }
    });
};

  const handleOpenEmailModal = () => {
    setModalContent("email");
    setModalOpen(true);
  };

  const handleOpenSmsModal = () => {
    setModalContent("sms");
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const resolvePlaceholders = (templateContent, placeholders) => {
    let resolvedContent = templateContent;
    Object.keys(placeholders).forEach(placeholder => {
      // Create a regex for each placeholder to find all instances in the content
      const regex = new RegExp(`{{${placeholder}}}`, "g");
      resolvedContent = resolvedContent.replace(regex, placeholders[placeholder]);
    });
    return resolvedContent;
  };

  const handleSendEmail = async (templateId, scheduledTime) => {
    console.log(`Sending email with template id: ${templateId} for visits: ${selectedRows.map(row => row.visitId).join(", ")}`);

    const template = templates.email.find(t => t.id === templateId);
    if (!template) {
        console.error("Template not found");
        return;
    }

    for (const { rowId: visitId, rowIsLegacy: isLegacy } of selectedRows) {
        try {
            const customerDetails = await fetchCustomerDetailsForVisit(visitId, isLegacy); // Pass isLegacy
            console.log('Customer Details:', customerDetails.customer_email);

            const resolvedHtml = resolvePlaceholders(template.content, customerDetails);

            const emailDetails = {
                to: customerDetails.customer_email,
                subject: template.name,
                text: resolvedHtml,
                html: resolvedHtml,
                email_type: isLegacy ? 'visit' : 'visit_new',
                related_id: visitId,
                scheduled_time: scheduledTime?.toISOString(), // Include scheduled time
                customer_id: customerDetails.customer_id,
                gravestone_id: customerDetails.gravestone_id,
                send_to_contacts: true // New parameter to fetch contacts in the backend
            };

            const response = await fetch(`${apiURL}/api/send-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(emailDetails),
            });

            if (response.ok) {
                const data = await response.json();
                console.log(`Email sent for visit ${visitId}:`, data.message);

                const updateResponse = await fetch(`${apiURL}/api/visits/${visitId}/update-notification`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ send_email: true, isLegacy }), // Include isLegacy in the update request
                });

                if (!updateResponse.ok) {
                    throw new Error(`Failed to update send_email status for visit ${visitId}`);
                }

                if (scheduledTime) {
                  createLog({ type: 'action', message: `E-post planlagt for besøk ${visitId}`, userId: user.id });
                } else {
                  createLog({ type: 'action', message: `E-post sendt for besøk ${visitId}`, userId: user.id });
                }

                console.log(`send_email status updated for visit ${visitId}`);
            } else {
                throw new Error(`Failed to send email for visit ${visitId}`);
            }
        } catch (error) {
            console.error(`Failed to send email and/or update send_email for visit ${visitId}:`, error);
        }
    }

    setModalOpen(false);
};

const handleSendSms = async (templateId, scheduledTime) => {
  console.log(`Sending SMS with template id: ${templateId} for visits: ${selectedRows.map(row => row.visitId).join(", ")}`);

  const template = templates.sms.find(t => t.id === templateId);
  if (!template) {
      console.error("Template not found");
      return;
  }

  for (const { rowId: visitId, rowIsLegacy: isLegacy } of selectedRows) {
      try {
          const customerDetails = await fetchCustomerDetailsForVisit(visitId, isLegacy); // Pass isLegacy

          const resolvedText = resolvePlaceholders(template.content, customerDetails);

          const smsDetails = {
              to: customerDetails.customer_phone,
              body: resolvedText,
              sms_type: isLegacy ? 'visit' : 'visit_new',
              related_id: visitId,
              scheduled_time: scheduledTime?.toISOString(), // Include scheduled time
              customer_id: customerDetails.customer_id,
              gravestone_id: customerDetails.gravestone_id,
              send_to_contacts: true // New parameter to fetch contacts in the backend
          };

          const response = await fetch(`${apiURL}/api/send-sms`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(smsDetails),
          });

          const data = await response.json();
          if (response.ok) {
              const updateResponse = await fetch(`${apiURL}/api/visits/${visitId}/update-notification`, {
                  method: 'PATCH',
                  headers: {
                      'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({ send_sms: true, isLegacy }), // Include isLegacy in the update request
              });

              if (!updateResponse.ok) {
                  throw new Error(`Failed to update send_sms status for visit ${visitId}`);
              }
              console.log(`send_sms status updated for visit ${visitId}`);
          } else {
              throw new Error(`Failed to send sms for visit ${visitId}`);
          }

          if (scheduledTime) {
            createLog({ type: 'action', message: `SMS planlagt for besøk ${visitId}`, userId: user.id });
          } else {
            createLog({ type: 'action', message: `SMS sendt for besøk ${visitId}`, userId: user.id });
          }

      } catch (error) {
          console.error(`Failed to send SMS for visit ${visitId}:`, error.message);
      }
  }

  setModalOpen(false);
};

const isSelected = (rowId, rowIsLegacy) => {
  return selectedRows.some(
      (row) => row.rowId === rowId && row.rowIsLegacy === rowIsLegacy
  );
};

  if (error)
    return <div className="text-red-500 text-center">Error: {error}</div>;

  return (
    <PageLayout>
      <ReusableHeaderV2 title="Besøk" linkTo="/besok/tommel-ned" linkText="Se Tommel Ned" icon={FaRegCalendarCheck} />
      <Topbar>
  <Toolbar>
    <ReusableLimit value={limit} onChange={handleLimitChange} />
    <ReusableDatePicker
      selectedDate={from_date}
      onChange={handleFromDateChange}
      label="Fra dato"
      name="from_date"
    />
    <ReusableDatePicker
      selectedDate={to_date}
      onChange={handleToDateChange}
      label="Til dato"
      name="to_date"
    />
    <div className="flex space-x-4 items-center">
      {/* Toggle for Send Email filter */}
      <label className="flex items-center space-x-2">
        <input
          type="checkbox"
          checked={filter_send_email === 'false'}
          onChange={(e) =>
            setFilterSendEmail(e.target.checked ? 'false' : '')
          }
        />
        <span>Vis ikke-sendte e-poster</span>
      </label>

      {/* Toggle for Send SMS filter */}
      <label className="flex items-center space-x-2">
        <input
          type="checkbox"
          checked={filter_send_sms === 'false'}
          onChange={(e) =>
            setFilterSendSms(e.target.checked ? 'false' : '')
          }
        />
        <span>Vis ikke-sendte SMS</span>
      </label>
      
      {/* Send Email Button */}
  <button
    onClick={handleOpenEmailModal}
    className="p-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-150 ease-in-out"
    title="Send E-post"
    aria-label="Send E-post"
  >
    <MdEmail size={24} />
  </button>

  {/* Send SMS Button */}
  <button
    onClick={handleOpenSmsModal}
    className="p-2 bg-green-500 text-white rounded hover:bg-green-700 transition duration-150 ease-in-out"
    title="Send SMS"
    aria-label="Send SMS"
  >
    <MdTextsms size={24} />
  </button>
    </div>
    <ReusableSearch value={search} onChange={handleSearchChange} />
  </Toolbar>
</Topbar>
      <ReusableTable
        columns={columns}
        data={visits}
        sort={{ field: sort, order: order }}
        onSort={handleSort}
        isSelected={isSelected}
        handleRowSelectionChange={handleRowSelectionChange}
        isLoading={isLoading}
      />
      <Pagination
        totalPages={totalPages}
        currentPage={page}
        setPage={setPage}
      />
      <Modal isOpen={modalOpen} onClose={handleCloseModal}>
      {modalContent === "email" && (
        <div>
          <h2 className="text-2xl font-bold text-gray-700 mb-4">Send E-post</h2>
          <div className="space-y-4">
            {templates.email
              .sort((a, b) => (b.is_default === "1" ? 1 : -1)) // Sort with default first
              .map((template) => {
                const isDefault = template.is_default === "1"; // Check if is_default is the string '1'
                return (
                  <div
                    key={template.id}
                    className={`border rounded-lg p-4 ${
                      isDefault ? "border-blue-500 bg-blue-50" : ""
                    }`}
                  >
                    <h3
                      className={`text-lg font-semibold ${
                        isDefault ? "text-blue-700" : ""
                      }`}
                    >
                      {template.name} {isDefault && <span>(Standard)</span>}
                    </h3>
                    <div
                      className="text-sm text-gray-600 overflow-hidden max-h-16"
                      dangerouslySetInnerHTML={{
                        __html: template.content.substring(0, 300) + "...",
                      }}
                    ></div>

                    <div className="mt-4">
                      <label className="block text-gray-700">Velg dato og tid:</label>
                      <DatePicker
                        selected={selectedDateTime}
                        onChange={(date) => setSelectedDateTime(date)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="MMMM d, yyyy h:mm aa"
                        className="mt-2 block w-full p-2 border rounded-lg"
                        placeholderText="Velg dato og tid"
                      />
                    </div>

                    <button
                      className={`mt-4 px-4 py-2 rounded ${
                        isDefault
                          ? "bg-blue-600 text-white hover:bg-blue-700"
                          : "bg-blue-500 text-white hover:bg-blue-700"
                      } transition duration-150 ease-in-out`}
                      onClick={() => handleSendEmail(template.id, selectedDateTime)}
                    >
                      Send
                    </button>
                  </div>
                );
              })}
          </div>
        </div>
      )}

      {modalContent === "sms" && (
        <div>
          <h2 className="text-2xl font-bold text-gray-700 mb-4">Send SMS</h2>
          <div className="space-y-4">
            {templates.sms
              .sort((a, b) => (b.is_default === "1" ? 1 : -1)) // Sort with default first
              .map((template) => {
                const isDefault = template.is_default === "1"; // Check if is_default is the string '1'
                return (
                  <div
                    key={template.id}
                    className={`border rounded-lg p-4 ${
                      isDefault ? "border-green-500 bg-green-50" : ""
                    }`}
                  >
                    <h3
                      className={`text-lg font-semibold ${
                        isDefault ? "text-green-700" : ""
                      }`}
                    >
                      {template.name} {isDefault && <span>(Standard)</span>}
                    </h3>
                    <p className="text-sm text-gray-600 overflow-hidden overflow-ellipsis max-h-16">
                      {template.content.substring(0, 100)}...
                    </p>

                    <div className="mt-4">
                      <label className="block text-gray-700">Velg dato og tid:</label>
                      <DatePicker
                        selected={selectedDateTime}
                        onChange={(date) => setSelectedDateTime(date)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="MMMM d, yyyy h:mm aa"
                        className="mt-2 block w-full p-2 border rounded-lg"
                        placeholderText="Velg dato og tid"
                      />
                    </div>

                    <button
                      className={`mt-4 px-4 py-2 rounded ${
                        isDefault
                          ? "bg-green-600 text-white hover:bg-green-700"
                          : "bg-green-500 text-white hover:bg-green-700"
                      } transition duration-150 ease-in-out`}
                      onClick={() => handleSendSms(template.id, selectedDateTime)}
                    >
                      Send
                    </button>
                  </div>
                );
              })}
          </div>
        </div>
      )}

        <div className="flex justify-end space-x-4 mt-4">
          <button
            onClick={handleCloseModal}
            className="px-4 py-2 bg-gray-500 text-white font-medium rounded hover:bg-gray-600 focus:outline-none"
          >
            Lukk
          </button>
        </div>
      </Modal>
    </PageLayout>
  );
}

async function fetchCustomerDetailsForVisit(visitId, isLegacy) {
  const apiURL = process.env.REACT_APP_API_BASE_URL; // Your API base URL
  const mainURL = process.env.REACT_APP_MAIN_URL || "";

  console.log('Fetching customer details for visit:', visitId);

  // Set fetchFrom based on isLegacy
  const fetchFrom = isLegacy ? 'visits' : 'visits_new';
  const endpoint = `${apiURL}/api/visits/${visitId}/customer-details?fetchFrom=${fetchFrom}`;

  try {
    const response = await fetch(endpoint);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();

    console.log('Fetched customer details for visit:', data);

    const visitUrl = `${mainURL}/ditt-besok/${visitId}`; // URL for new visits
    const legacyVisitUrl = `https://cms.gravstellerne.no/visitinfo/${visitId}`; // URL for legacy visits

    const customerDetails = {
      customer_id: data.customerDetails.customer_id,
      customer_firstname: data.customerDetails.customer_firstname,
      customer_lastname: data.customerDetails.customer_lastname,
      customer_email: data.customerDetails.customer_email,
      customer_phone: data.customerDetails.customer_phone,
      visit_url: isLegacy ? legacyVisitUrl : visitUrl, // Choose the correct URL
      gravestone_id: data.customerDetails.gravestone_id,
      // Add more customer details as needed
    };

    console.log('Customer details for visit:', customerDetails);

    return customerDetails;
  } catch (error) {
    console.error('Failed to fetch customer details:', error);
    throw error;
  }
}

export default Visits;
