import React, { useState, useEffect } from 'react';
import { FaClipboardList, FaCheck } from 'react-icons/fa';
import Topbar from '../../components/Topbar';
import Toolbar from '../../components/Toolbar';
import ReusableTable from '../../components/ReusableTable';
import Pagination from '../../components/Pagination';
import Spinner from '../../components/Spinner';
import ReusableSearch from '../../components/ReusableSearch';
import ReusableLimit from '../../components/ReusableLimit';
import PageLayout from '../../layouts/PageLayout';
import ReusableHeaderV2 from '../../components/ReusableHeaderV2';
import useFollowUps from '../../hooks/useFollowUps';

function FollowUps() {
  const { followUps, pagination, loading, error, fetchFollowUps, updateFollowUp } = useFollowUps();
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('id');
  const [order, setOrder] = useState('ASC');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    fetchFollowUps({ search, sort, order, page, limit });
  }, [search, sort, order, page, limit]);

  const handleSearchChange = (e) => setSearch(e.target.value);
  const handleLimitChange = (e) => setLimit(Number(e.target.value));

  const handleSort = (field) => {
    if (sort === field) {
      setOrder(order === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setSort(field);
      setOrder('ASC');
    }
  };

  const handleResolveFollowUp = async (id, comment) => {
    try {
      await updateFollowUp(id, { resolved: true, comment });
      fetchFollowUps({ search, sort, order, page, limit });
    } catch (err) {
      console.error('Feil ved å markere oppfølging som løst:', err);
      alert('Kunne ikke markere oppfølging som løst.');
    }
  };

  const handleRowRightClick = (row) => [
    {
      label: 'Vis detaljer',
      icon: FaClipboardList,
      onClick: () => alert(`Vis detaljer for oppfølging ID ${row.id}`),
    },
    {
      label: 'Marker som løst',
      icon: FaCheck,
      onClick: () => handleResolveFollowUp(row.id, row.comment),
      disabled: row.resolved, // Deaktiver hvis allerede løst
    },
  ];

  const columns = [
    { label: 'ID', accessor: 'id', width: '100px' },
    { label: 'Gravstein ID', accessor: 'gravestone_id', link: (row) => `/gravsteiner/${row.gravestone_id}`, width: '150px' },
    { label: 'Bruker ID', accessor: 'user_id', width: '150px' },
    { label: 'Kommentar', accessor: 'comment', width: '300px' },
    { label: 'Besøks ID', accessor: 'visit_id', link: (row) => `/besok/${row.visit_id}/detaljer/0`, width: '150px' },
    { label: 'Løst', accessor: 'resolved', type: 'boolean', width: '100px' },
    { label: 'Opprettet', accessor: 'created_at', type: 'date-time', width: '200px' },
    { label: 'Løst dato', accessor: 'resolved_at', type: 'date-time', width: '200px' },
  ];

  if (error) return <div className="text-red-500 text-center">Feil: {error}</div>;

  return (
    <PageLayout>
      <ReusableHeaderV2 title="Oppfølginger" icon={FaClipboardList} />
      <Topbar>
        <Toolbar>
          <ReusableLimit value={limit} onChange={handleLimitChange} />
          <ReusableSearch value={search} onChange={handleSearchChange} />
        </Toolbar>
      </Topbar>
      {loading && <Spinner />}
      <ReusableTable
        columns={columns}
        data={followUps}
        sort={{ field: sort, order }}
        onSort={handleSort}
        onRowRightClick={handleRowRightClick}
      />
      <Pagination totalPages={pagination.totalPages} currentPage={page} setPage={setPage} />
    </PageLayout>
  );
}

export default FollowUps;