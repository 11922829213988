// src/components/ProtectedRoute.js

import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Navigate, useLocation } from 'react-router-dom';

function ProtectedRoute({ children }) {
  const { user } = useContext(AuthContext);
  const location = useLocation();

  if (!user) {
    return <Navigate to="/logginn" state={{ from: location }} replace />;
  }

  return children;
}

export default ProtectedRoute;