import React, { useState, useEffect } from "react";
import Topbar from "../../components/Topbar";
import Toolbar from "../../components/Toolbar";
import ReusableTable from "../../components/ReusableTable";
import Pagination from "../../components/Pagination";
import Spinner from "../../components/Spinner";
import ReusableSearch from "../../components/ReusableSearch";
import ReusableLimit from "../../components/ReusableLimit";
import ReusableHeader from "../../components/ReusableHeader";

function SmsLogs() {
  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("created_at");
  const [order, setOrder] = useState("DESC");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const apiURL = process.env.REACT_APP_API_BASE_URL || "";

  // Event handlers remain within the parent component
  const handleSearchChange = (e) => setSearch(e.target.value);
  const handleLimitChange = (e) => setLimit(e.target.value);

  useEffect(() => {
    const fetchLogs = async () => {
      setIsLoading(true);
      try {
        const queryParams = new URLSearchParams({
          search,
          sort,
          order,
          page,
          limit,
        }).toString();
        const response = await fetch(`${apiURL}/api/sms-logs?${queryParams}`);
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
        const data = await response.json();
        setLogs(data.logs);
        setTotalPages(data.totalPages);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLogs();
  }, [search, sort, order, page, limit]);

  const handleSort = (field) => {
    if (sort === field) {
      setOrder(order === "ASC" ? "DESC" : "ASC");
    } else {
      setSort(field);
      setOrder("ASC");
    }
  };

  // Adjust columns as per your gravestone data structure
  const columns = [
    { label: "ID", accessor: "id", width: "50px" },
    { label: "Telefonnummer", accessor: "phone_number", width: "150px" },
    { label: "Type", accessor: "sms_type", width: "150px" },
    {
      label: "Relatert ID",
      accessor: "related_id",
      link: (row) => {
        const legacyStatus = row.sms_type === "visit" ? "1" : "0";
        return `/besok/${row.related_id}/detaljer/${legacyStatus}`;
      },
      width: "100px",
    },
    {
      label: "Opprettet",
      accessor: "created_at",
      type: "date",
      width: "200px",
    },
    { label: "Suksess", accessor: "success", type: "boolean", width: "100px" },
    // Add more columns as needed
  ];

  if (error)
    return <div className="text-red-500 text-center">Error: {error}</div>;

  return (
    <div className="overflow-x-auto relative">
      <Topbar>
        <ReusableHeader title="Logger: SMS" />
        {/* Rest of the component similar to Customers component */}
        <Toolbar>
          <ReusableLimit value={limit} onChange={handleLimitChange} />
          <ReusableSearch value={search} onChange={handleSearchChange} />
        </Toolbar>
      </Topbar>
      <ReusableTable
        columns={columns}
        data={logs}
        sort={{ field: sort, order: order }}
        onSort={handleSort}
        isLoading={isLoading}
      />
      <Pagination
        totalPages={totalPages}
        currentPage={page}
        setPage={setPage}
      />
    </div>
  );
}

export default SmsLogs;
