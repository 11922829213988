import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCustomer } from '../context/CustomerContext';
import { useNotifications } from '../context/NotificationContext';

function CustomerLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [resetToken, setResetToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [resetStep, setResetStep] = useState(1); // 1 for email input, 2 for token and new password input
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useCustomer();
  const { addNotification } = useNotifications();
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';
  const from = location.state?.from?.pathname || '/kunde';

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(`${apiURL}/api/customer/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.message || 'An error occurred');
        setIsLoading(false);
        return;
      }

      localStorage.setItem('accessToken', data.accessToken);
      localStorage.setItem('refreshToken', data.refreshToken);
      
      login({ id: data.customerId, name: data.customerName, email: data.customerEmail, type: data.userType, showGravepersonPicture: data.showGravepersonPicture, firstLogin: data.firstLogin, accessToken: data.accessToken });
      addNotification({ message: 'Logget inn.', type: 'success' });
      navigate(from, { replace: true });
    } catch (err) {
      console.error('Login error:', err);
      setError('Failed to login');
      setIsLoading(false);
    }
  };

  const handlePasswordResetRequest = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(`${apiURL}/api/request-password-reset`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.message || 'An error occurred');
        setIsLoading(false);
        return;
      }

      addNotification({ message: 'Engangskode sendt.', type: 'success' });
      setResetStep(2);
      setIsLoading(false);
    } catch (err) {
      console.error('Password reset request error:', err);
      setError('Failed to request password reset');
      setIsLoading(false);
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(`${apiURL}/api/reset-password-customer`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, token: resetToken, newPassword }),
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.message || 'An error occurred');
        setIsLoading(false);
        return;
      }

      addNotification({ message: 'Password reset successful.', type: 'success' });
      setIsResettingPassword(false);
      setResetStep(1);
      setEmail('');
      setResetToken('');
      setNewPassword('');
      setIsLoading(false);
    } catch (err) {
      console.error('Password reset error:', err);
      setError('Failed to reset password');
      setIsLoading(false);
    }
  };

  // Rest of the component code

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-orange-grave to-orange-300">
      <div className="max-w-md w-full space-y-8 p-10 bg-white rounded-xl shadow-lg">
        <div className="flex justify-center">
          <img className="h-14 w-auto" src="/images/gravstellerne-logo-kundeportal.png" alt="Customer Logo" />
        </div>
        {error && (
          <p className="text-center text-red-500 text-sm italic">{error}</p>
        )}
        {!isResettingPassword ? (
          <form onSubmit={handleLogin} className="mt-8 space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                E-post
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-orange-grave focus:border-orange-grave focus:z-10 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Passord
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-orange-grave focus:border-orange-grave focus:z-10 sm:text-sm"
              />
            </div>
            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-grave hover:bg-orange-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                {isLoading ? 'Logger inn...' : 'Logg Inn'}
              </button>
            </div>
            <div className="text-center">
              <button
                type="button"
                onClick={() => setIsResettingPassword(true)}
                className="text-sm text-orange-grave hover:text-orange-300"
              >
                Glemt passord?
              </button>
            </div>
          </form>
        ) : (
          <div>
            {resetStep === 1 ? (
              <form onSubmit={handlePasswordResetRequest} className="mt-8 space-y-6">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    E-post
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  >
                    {isLoading ? 'Sender kode...' : 'Send Tilbakestillingskode'}
                  </button>
                </div>
                <div className="text-center">
                  <button
                    type="button"
                    onClick={() => setIsResettingPassword(false)}
                    className="text-sm text-purple-500 hover:text-purple-700"
                  >
                    Tilbake til innlogging
                  </button>
                </div>
              </form>
            ) : (
              <form onSubmit={handlePasswordReset} className="mt-8 space-y-6">
                <div>
                  <label htmlFor="resetToken" className="block text-sm font-medium text-gray-700">
                    Tilbakestillingskode
                  </label>
                  <input
                    type="text"
                    id="resetToken"
                    name="resetToken"
                    value={resetToken}
                    onChange={(e) => setResetToken(e.target.value)}
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                  />
                </div>
                <div>
                  <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
                    Nytt Passord
                  </label>
                  <input
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  >
                    {isLoading ? 'Tilbakestiller passord...' : 'Tilbakestill Passord'}
                  </button>
                </div>
                <div className="text-center">
                  <button
                    type="button"
                    onClick={() => {
                      setIsResettingPassword(false);
                      setResetStep(1);
                    }}
                    className="text-sm text-purple-500 hover:text-purple-700"
                  >
                    Tilbake til innlogging
                  </button>
                </div>
              </form>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomerLogin;