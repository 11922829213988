import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Topbar from "../../components/Topbar";
import Toolbar from "../../components/Toolbar";
import ReusableTable from "../../components/ReusableTable";
import Pagination from "../../components/Pagination";
import Spinner from "../../components/Spinner";
import ReusableSearch from "../../components/ReusableSearch";
import ReusableLimit from "../../components/ReusableLimit";
import PageLayout from "../../layouts/PageLayout";
import ReusableHeaderV2 from "../../components/ReusableHeaderV2";
import { FaClipboardList } from "react-icons/fa";

function Workorders() {
  const [workorders, setWorkorders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("work_date");
  const [order, setOrder] = useState("DESC");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [totalPages, setTotalPages] = useState(0);

  const apiURL = process.env.REACT_APP_API_BASE_URL || "";

  // Event handlers remain within the parent component
  const handleSearchChange = (e) => setSearch(e.target.value);
  const handleLimitChange = (e) => setLimit(e.target.value);

  useEffect(() => {
    const fetchWorkorders = async () => {
      setIsLoading(true);
      try {
        const queryParams = new URLSearchParams({
          search,
          sort,
          order,
          page,
          limit,
        }).toString();
        const response = await fetch(`${apiURL}/api/workorders?${queryParams}`);
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
        const data = await response.json();
        setWorkorders(data.workorders);
        setTotalPages(data.totalPages);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchWorkorders();
  }, [search, sort, order, page, limit]);

  const handleSort = (field) => {
    if (sort === field) {
      setOrder(order === "ASC" ? "DESC" : "ASC");
    } else {
      setSort(field);
      setOrder("ASC");
    }
  };

  const columns = [
    { label: "ID", accessor: "id", width: "50px" },
    {
      label: "Navn",
      accessor: "name",
      link: (row) => `/arbeidslister/${row.id}`,
      width: "150px",
    },
    { label: "Gravsteiner", accessor: "gravestone_count", width: "100px" },
    {
      label: "Arbeidere",
      accessor: "user_names",
      type: "names",
      width: "200px",
    },
    {
      label: "Aktiviteter",
      accessor: "activity_names",
      type: "span",
      width: "200px",
    },
    { label: "Startdato", accessor: "work_date", type: "date", width: "150px" },
    { label: "Frist", accessor: "due_date", type: "date", width: "150px" },
    { label: "Status", accessor: "status_name", width: "100px" },
  ];

  if (error)
    return <div className="text-red-500 text-center">Error: {error}</div>;

  return (
    <PageLayout>
      <ReusableHeaderV2
        title="Arbeidslister"
        linkTo="/arbeidslister/ny"
        icon={FaClipboardList}
      />
      <Topbar>
        <Toolbar>
          <ReusableLimit value={limit} onChange={handleLimitChange} />
          <ReusableSearch value={search} onChange={handleSearchChange} />
        </Toolbar>
      </Topbar>
      <ReusableTable
        columns={columns}
        data={workorders}
        sort={{ field: sort, order: order }}
        onSort={handleSort}
        isLoading={isLoading}
      />
      <Pagination
        totalPages={totalPages}
        currentPage={page}
        setPage={setPage}
      />
    </PageLayout>
  );
}

export default Workorders;
