import React, { createContext, useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Function to log in the user
  const login = async (email, password) => {
    try {
      const response = await axiosInstance.post('/api/login', { email, password });
      const data = response.data;

      // Store tokens in localStorage
      localStorage.setItem('accessToken', data.accessToken);
      localStorage.setItem('refreshToken', data.refreshToken);

      // Store user details in localStorage
      localStorage.setItem('userId', data.userId);
      localStorage.setItem('userName', data.userName);
      localStorage.setItem('userType', data.userType);

      // Update the user state
      setUser({ id: data.userId, name: data.userName, type: data.userType });
    } catch (err) {
      console.error('Login failed:', err);
      throw err;
    }
  };

  // Function to log out the user
  const logout = () => {
    const refreshToken = localStorage.getItem('refreshToken');

    // Invalidate the refresh token on the server
    axiosInstance.post('/api/logout', { token: refreshToken }).catch((err) => {
      console.error('Logout error:', err);
    });

    // Remove tokens and user details from localStorage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('userType');

    // Clear the user state
    setUser(null);
  };

  // Initialize user from localStorage
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('userId');
    const userName = localStorage.getItem('userName');
    const userType = localStorage.getItem('userType');

    if (accessToken && userId && userName && userType) {
      setUser({ id: userId, name: userName, type: userType });
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};