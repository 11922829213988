import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNotifications } from '../context/NotificationContext';
import { AiOutlineCheckCircle, AiOutlineInfoCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import axios from 'axios';

const Notification = () => {
  const { notifications, removeNotification } = useNotifications();
  const [settings, setSettings] = React.useState({ duration: 5000, position: 'bottom-right' });
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(`${apiURL}/api/site_settings/notification_settings`);
        setSettings(response.data.setting_value);
      } catch (error) {
        console.error('Error fetching notification settings:', error);
      }
    };

    fetchSettings();
    const interval = setInterval(fetchSettings, 60000); // Refresh settings every 60 seconds
    return () => clearInterval(interval);
  }, [apiURL]);

  useEffect(() => {
    notifications.forEach((notif) => {
      const timer = setTimeout(() => {
        removeNotification(notif.id);
      }, notif.duration || settings.duration); // Use individual duration or system duration
      return () => clearTimeout(timer); // Clear individual timers
    });
  }, [notifications, removeNotification, settings.duration]);

  const ICONS = {
    success: <AiOutlineCheckCircle size={24} />,
    error: <AiOutlineCloseCircle size={24} />,
    info: <AiOutlineInfoCircle size={24} />,
  };

  const POSITION_CLASSES = {
    'top-left': 'fixed top-4 left-4',
    'top-center': 'fixed top-4 left-1/2 transform -translate-x-1/2',
    'top-right': 'fixed top-4 right-4',
    'center-left': 'fixed top-1/2 left-4 transform -translate-y-1/2',
    'center': 'fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
    'center-right': 'fixed top-1/2 right-4 transform -translate-y-1/2',
    'bottom-left': 'fixed bottom-4 left-4',
    'bottom-center': 'fixed bottom-4 left-1/2 transform -translate-x-1/2',
    'bottom-right': 'fixed bottom-4 right-4',
  };

  const notificationVariants = {
    hidden: { opacity: 0, x: 20 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -20 },
  };

  return (
    <div
      className={`${POSITION_CLASSES[settings.position] || 'fixed bottom-4 right-4'} z-50 space-y-4`}
    >
      <AnimatePresence>
        {notifications.map((notif) => (
          <motion.div
            key={notif.id}
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={notificationVariants}
            transition={{ duration: 0.4, ease: 'easeInOut' }}
            className={`relative flex items-center p-6 shadow-xl rounded-lg bg-white border-l-4 ${
              notif.type === 'success'
                ? 'border-green-500'
                : notif.type === 'error'
                ? 'border-red-500'
                : 'border-blue-500'
            }`}
          >
            <div
              className={`flex items-center justify-center w-12 h-12 rounded-full ${
                notif.type === 'success'
                  ? 'bg-green-100 text-green-500'
                  : notif.type === 'error'
                  ? 'bg-red-100 text-red-500'
                  : 'bg-blue-100 text-blue-500'
              }`}
            >
              {ICONS[notif.type || 'info']}
            </div>
            <div className="ml-4 flex-1">
              <p className="text-lg font-semibold text-gray-800">{notif.message}</p>
              <div
                className={`h-1 rounded-full mt-2 bg-opacity-20 ${
                  notif.type === 'success'
                    ? 'bg-green-500'
                    : notif.type === 'error'
                    ? 'bg-red-500'
                    : 'bg-blue-500'
                }`}
              >
                <motion.div
                  className={`h-full rounded-full ${
                    notif.type === 'success'
                      ? 'bg-green-500'
                      : notif.type === 'error'
                      ? 'bg-red-500'
                      : 'bg-blue-500'
                  }`}
                  initial={{ width: '100%' }}
                  animate={{ width: 0 }}
                  transition={{ duration: (notif.duration || settings.duration) / 1000, ease: 'linear' }}
                ></motion.div>
              </div>
            </div>
            <button
              className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 focus:outline-none"
              onClick={() => removeNotification(notif.id)}
              aria-label="Close"
            >
              &times;
            </button>
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

export default Notification;