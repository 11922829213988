import React, { useState, useEffect } from 'react';
import { useNotifications } from '../../context/NotificationContext';
import Topbar from '../../components/Topbar';
import Toolbar from '../../components/Toolbar';
import ReusableTable from '../../components/ReusableTable';
import Pagination from '../../components/Pagination';
import Spinner from '../../components/Spinner';
import ReusableSearch from '../../components/ReusableSearch';
import ReusableLimit from '../../components/ReusableLimit';
import PageLayout from '../../layouts/PageLayout';
import ReusableHeaderV2 from '../../components/ReusableHeaderV2';
import { FaCalendarAlt } from 'react-icons/fa';
import useSpecialDays from '../../hooks/useSpecialDays';
import Error from '../../components/Error';

function SpecialDays() {
  const { addNotification } = useNotifications();
  const { specialDays, pagination, loading, error, fetchSpecialDays, setActiveSpecialDay } = useSpecialDays();
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('date');
  const [order, setOrder] = useState('ASC');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    fetchSpecialDays({ search, sort, order, page, limit });
  }, [search, sort, order, page, limit]);

  const handleSearchChange = (e) => setSearch(e.target.value);
  const handleLimitChange = (e) => setLimit(Number(e.target.value));

  const handleSort = (field) => {
    if (sort === field) {
      setOrder(order === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setSort(field);
      setOrder('ASC');
    }
  };

  const handleRowRightClick = (row) => [
    {
      label: 'Vis detaljer',
      icon: FaCalendarAlt,
      onClick: () => alert(`Detaljer for Spesiell Dag ID ${row.id}`),
    },
    {
      label: 'Sett aktiv',
      icon: FaCalendarAlt,
      onClick: () => handleSetActive(row),
      disabled: row.active, // Disable if already active
    },
  ];
  
  const handleSetActive = async (row) => {
    try {
      await setActiveSpecialDay(row.id, row.gravestone_id); // Call the hook method to set active
      addNotification({ message: 'Spesiell dag satt som aktiv.', type: 'success' });
      fetchSpecialDays({ search, sort, order, page, limit }); // Refresh the list
    } catch (error) {
      console.error('Failed to set active special day:', error);
      addNotification({ message: 'Noe gikk galt. Prøv igjen.', type: 'error' });
    }
  };

  const columns = [
    { label: 'ID', accessor: 'id', width: '100px' },
    { label: 'Gravstein ID', accessor: 'gravestone_id', link: (row) => `/gravsteiner/${row.gravestone_id}`, width: '150px' },
    { label: 'Beskrivelse', accessor: 'description', width: '300px' },
    { label: 'Dato', accessor: 'date', type: 'recurring-date', width: '200px' },
    { label: 'Gjentakende', accessor: 'is_recurring', type: 'boolean', width: '100px' },
    { label: 'Aktiv', accessor: 'active', type: 'boolean', width: '100px' },
  ];

  if (error) {
    return <Error message={error} retryAction={() => fetchSpecialDays({ search, sort, order, page, limit })} />;
  }

  return (
    <PageLayout>
      <ReusableHeaderV2 title="Spesielle Dager" icon={FaCalendarAlt} />
      <Topbar>
        <Toolbar>
          <ReusableLimit value={limit} onChange={handleLimitChange} />
          <ReusableSearch value={search} onChange={handleSearchChange} />
        </Toolbar>
      </Topbar>
      {loading ? (
        <Spinner />
      ) : (
        <ReusableTable
          columns={columns}
          data={specialDays}
          sort={{ field: sort, order }}
          onSort={handleSort}
          onRowRightClick={handleRowRightClick}
        />
      )}
      <Pagination totalPages={pagination.totalPages} currentPage={page} setPage={setPage} />
    </PageLayout>
  );
}

export default SpecialDays;