import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { useNotifications } from '../../context/NotificationContext';
import LoadingScreen from '../../components/LoadingScreen';
import { FaEye, FaEyeSlash, FaCamera } from 'react-icons/fa';

const UserProfileEdit = () => {
  const { id } = useParams();
  const [profile, setProfile] = useState({
    name: '',
    email: '',
    picture: null,
    password: '',
    confirmPassword: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [picturePreview, setPicturePreview] = useState(null); // New state for picture preview

  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { addNotification } = useNotifications();

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    if (parseInt(user.id) !== parseInt(id)) {
      addNotification({ message: 'Dette er ikke din profil og du kan ikke redigere den.', type: 'error' });
      navigate(`/brukere/${id}`);
      return;
    }
    const fetchUserData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${apiURL}/api/users/${id}`);
        if (!response.ok) throw new Error('Data could not be fetched!');
        const profileData = await response.json();
        setProfile(profileData);
        setPicturePreview(profileData.picture ? `${apiURL}/${profileData.picture}` : '/images/default_picture.jpg'); // Set the initial preview
      } catch (error) {
        console.error("Fetching profile data failed:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setProfile((prevProfile) => ({ ...prevProfile, [name]: files[0] }));
      setPicturePreview(URL.createObjectURL(files[0])); // Set the preview URL
    } else {
      setProfile((prevProfile) => ({ ...prevProfile, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', profile.name);
    formData.append('email', profile.email);
    if (profile.password) formData.append('password', profile.password);
    if (profile.picture) formData.append('picture', profile.picture);

    try {
      const response = await fetch(`${apiURL}/api/users/${id}`, {
        method: 'PUT',
        body: formData,
      });
      if (!response.ok) throw new Error('Update failed!');
      navigate(`/brukere/${id}`);
    } catch (error) {
      console.error("Updating user failed:", error);
      addNotification({ message: 'Failed to update user.', type: 'error' });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  if (isLoading) return <LoadingScreen />;

  return (
    <div className="max-w-4xl mx-auto my-8">
      <h2 className="text-2xl font-semibold mb-6 text-center">Edit User Profile</h2>
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="flex justify-center mb-4">
          <div className="relative">
            <img src={picturePreview} alt="User Profile" className="rounded-full w-24 h-24 object-cover" />
            <label htmlFor="picture" className="absolute -bottom-2 -right-2 bg-green-500 rounded-full p-2 cursor-pointer">
              <input type="file" name="picture" id="picture" onChange={handleChange} className="hidden" />
              <FaCamera />
            </label>
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            Name:
          </label>
          <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" name="name" value={profile.name} onChange={handleChange} />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email:
          </label>
          <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="email" name="email" value={profile.email} onChange={handleChange} />
        </div>
        <div className="relative mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
            Password:
          </label>
          <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type={showPassword ? "text" : "password"} name="password" value={profile.password} onChange={handleChange} />
          <span className="absolute inset-y-0 top-7 right-0 pr-3 flex items-center text-md leading-5">
            <button type="button" onClick={togglePasswordVisibility} className="text-gray-700">
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </span>
        </div>
        <div className="relative mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
            Bekreft Passord:
          </label>
          <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type={showPassword ? "text" : "password"} name="confirmPassword" value={profile.confirmPassword} onChange={handleChange} />
          <span className="absolute inset-y-0 top-7 right-0 pr-3 flex items-center text-md leading-5">
            <button type="button" onClick={togglePasswordVisibility} className="text-gray-700">
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </span>
        </div>
        <div className="flex items-center justify-between">
          <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserProfileEdit;