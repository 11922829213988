import { useState, useEffect } from "react";
import axiosInstance from "../utils/axiosInstance";
import { useNotifications } from "../context/NotificationContext";

const useFollowUps = () => {
  const [followUps, setFollowUps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalItems: 0,
    totalPages: 0,
  });
  const { addNotification } = useNotifications();

  // Fetch follow-ups with optional query parameters
  const fetchFollowUps = async (queryParams = {}) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get("/api/follow-ups", { params: queryParams });
      const { followUps, totalItems, totalPages } = response.data;

      setFollowUps(followUps);
      setPagination((prev) => ({
        ...prev,
        page: queryParams.page || 1,
        limit: queryParams.limit || 10,
        totalItems,
        totalPages,
      }));
    } catch (err) {
      setError(err.response?.data?.message || "Failed to fetch follow-ups.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch follow-ups by gravestone ID
  const fetchFollowUpsByGravestone = async (gravestoneId, queryParams = {}) => {
    await fetchFollowUps({ ...queryParams, gravestoneId });
  };

  // Fetch follow-ups by visit ID
  const fetchFollowUpsByVisit = async (visitId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get("/api/follow-ups", { params: { visitId } });
      return response.data.followUps || [];
    } catch (err) {
      setError(err.response?.data?.message || "Failed to fetch follow-ups for visit.");
      return [];
    } finally {
      setLoading(false);
    }
  };

  // Create a new follow-up
  const createFollowUp = async (data) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.post("/api/follow-ups", data);
      setFollowUps((prev) => [...prev, response.data]); // Add to local state
      addNotification({ message: "Oppfølging opprettet", type: "info", duration: 1000 });
      return response.data;
    } catch (err) {
      setError(err.response?.data?.message || "Failed to create follow-up.");
      throw err; // Re-throw the error for handling by the caller
    } finally {
      setLoading(false);
    }
  };

  // Update an existing follow-up
  const updateFollowUp = async (id, data) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.put(`/api/follow-ups/${id}`, data);
      setFollowUps((prev) =>
        prev.map((followUp) => (followUp.id === id ? response.data : followUp))
      ); // Update local state
      addNotification({ message: "Oppfølging oppdatert", type: "info", duration: 1000 });
      return response.data;
    } catch (err) {
      setError(err.response?.data?.message || "Failed to update follow-up.");
      throw err; // Re-throw the error for handling by the caller
    } finally {
      setLoading(false);
    }
  };

  // Delete a follow-up
  const deleteFollowUp = async (id) => {
    setLoading(true);
    setError(null);
    try {
      await axiosInstance.delete(`/api/follow-ups/${id}`);
      setFollowUps((prev) => prev.filter((followUp) => followUp.id !== id)); // Remove from local state
      addNotification({ message: "Oppfølging slettet", type: "info", duration: 1000 });
    } catch (err) {
      setError(err.response?.data?.message || "Failed to delete follow-up.");
      throw err; // Re-throw the error for handling by the caller
    } finally {
      setLoading(false);
    }
  };

  return {
    followUps,
    pagination,
    loading,
    error,
    fetchFollowUps,
    fetchFollowUpsByGravestone,
    fetchFollowUpsByVisit,
    createFollowUp,
    updateFollowUp,
    deleteFollowUp,
  };
};

export default useFollowUps;