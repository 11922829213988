import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Topbar from "../../components/Topbar";
import Toolbar from "../../components/Toolbar";
import ReusableTable from "../../components/ReusableTable";
import Pagination from "../../components/Pagination";
import Spinner from "../../components/Spinner";
import ReusableSearch from "../../components/ReusableSearch";
import ReusableLimit from "../../components/ReusableLimit";
import ReusableActive from "../../components/ReusableActive";
import PageLayout from "../../layouts/PageLayout";
import ReusableHeaderV2 from "../../components/ReusableHeaderV2";
import { FaUser } from "react-icons/fa";

import { useUsers } from "../../hooks";

function Users() {
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState("ASC");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [active, setActive] = useState("1");

  // Fetch users using the custom hook
  const { users, isLoading, error, fetchUsers, totalPages } = useUsers();

  // Event handlers remain within the parent component
  const handleSearchChange = (e) => setSearch(e.target.value);
  const handleLimitChange = (e) => setLimit(e.target.value);
  const handleActiveChange = (e) => setActive(e.target.value);

  useEffect(() => {
    fetchUsers({ search, sort, order, page, limit, active });
  }, [search, sort, order, page, limit, active]);

  const handleSort = (field) => {
    if (sort === field) {
      setOrder(order === "ASC" ? "DESC" : "ASC");
    } else {
      setSort(field);
      setOrder("ASC");
    }
  };

  const columns = [
    {
      label: "Navn",
      accessor: "name",
      link: (row) => `/brukere/${row.id}`,
      width: "150px",
    },
    { label: "E-post", accessor: "email", width: "200px" },
    { label: "Type", accessor: "type", width: "150px" },
    { label: "Aktiv?", accessor: "active", type: "boolean", width: "100px" },
  ];

  if (error)
    return <div className="text-red-500 text-center">Error: {error}</div>;

  return (
    <PageLayout>
      <ReusableHeaderV2 title="Brukere" linkTo="/brukere/ny" icon={FaUser} />
      <Topbar>
        <Toolbar>
          <ReusableLimit value={limit} onChange={handleLimitChange} />
          <ReusableActive value={active} onChange={handleActiveChange} />
          <ReusableSearch value={search} onChange={handleSearchChange} />
        </Toolbar>
      </Topbar>
      <ReusableTable
        columns={columns}
        data={users}
        sort={{ field: sort, order: order }}
        onSort={handleSort}
        isLoading={isLoading}
      />
      <Pagination
        totalPages={totalPages}
        currentPage={page}
        setPage={setPage}
      />
    </PageLayout>
  );
}

export default Users;
