import { useState, useRef } from "react";
import { AiOutlineWarning } from "react-icons/ai";

const FollowUpButton = ({ followUp, onToggleFollowUp, onAddComment, isResolved }) => {
  const [holdTimeout, setHoldTimeout] = useState(null);
  const [isHolding, setIsHolding] = useState(false);
  const isTouchEvent = useRef(false); // Track if the event is touch-based

  const handleMouseDown = () => {
    if (isTouchEvent.current || isResolved) return; // Ignore mouse events if touch is detected or resolved
    startHoldTimeout();
  };

  const handleMouseUp = () => {
    if (isTouchEvent.current || isResolved) return; // Ignore mouse events if touch is detected or resolved
    clearHoldTimeoutAndToggle();
  };

  const handleTouchStart = () => {
    if (isResolved) return; // Ignore touch events if resolved
    isTouchEvent.current = true; // Mark as touch-based interaction
    startHoldTimeout();
  };

  const handleTouchEnd = () => {
    if (isResolved) return; // Ignore touch events if resolved
    clearHoldTimeoutAndToggle();
  };

  const startHoldTimeout = () => {
    setIsHolding(false);
    const timeout = setTimeout(() => {
      setIsHolding(true);
      openModal();
    }, 600);
    setHoldTimeout(timeout);
  };

  const clearHoldTimeoutAndToggle = () => {
    if (holdTimeout) {
      clearTimeout(holdTimeout);
      setHoldTimeout(null);

      if (!isHolding) {
        onToggleFollowUp(); // Delegate toggling to the parent
      } else {
        setIsHolding(false);
      }
    }
  };

  const openModal = () => {
    setHoldTimeout(null);
    onAddComment(); // Open the modal
  };

  return (
    <button
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseLeave={() => {
        if (holdTimeout) {
          clearTimeout(holdTimeout);
          setHoldTimeout(null);
        }
      }}
      disabled={isResolved} // Disable the button when resolved
      className={`flex flex-col items-center justify-center p-4 border rounded-lg ${
        isResolved
          ? "bg-gray-400 text-gray-600 cursor-not-allowed"
          : followUp
          ? "bg-yellow-500 text-white"
          : "bg-gray-200 text-gray-800"
      }`}
    >
      <AiOutlineWarning size={48} />
    </button>
  );
};

export default FollowUpButton;