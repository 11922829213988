import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext'; // Import the AuthContext
import Topbar from '../../components/Topbar';
import Toolbar from '../../components/Toolbar';
import Pagination from '../../components/Pagination';
import Spinner from '../../components/Spinner';
import ReusableSearch from '../../components/ReusableSearch';
import ReusableLimit from '../../components/ReusableLimit';
import PageLayout from '../../layouts/PageLayout';
import ReusableHeaderV2 from '../../components/ReusableHeaderV2';
import GraveyardPicker from '../../components/GraveyardPicker';
import MarkAllSeenButton from '../../components/MarkAllSeenButton';
import VisitCommentsTable from '../../components/VisitCommentsTable'; // Import the custom VisitCommentsTable
import Modal from '../../components/Modal'; // Import the Modal component
import VisitComment from '../../components/VisitComment'; // Import the VisitComment component
import { FaComments } from 'react-icons/fa';

function VisitComments() {
  const { user } = useContext(AuthContext); // Get the user object from the AuthContext
  const location = useLocation();
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('created_at');
  const [order, setOrder] = useState('DESC');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [seen_by_customer, setSeenByCustomer] = useState('');
  const [seen_by_user, setSeenByUser] = useState('');
  const [visit_type, setVisitType] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [gravestone_id, setGravestoneId] = useState('');
  const [graveyardId, setGraveyardId] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false); // State to handle modal visibility
  const [selectedComment, setSelectedComment] = useState(null); // State to track selected comment

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  const checkParams = () => {
    const params = new URLSearchParams(location.search);
    const graveyardIdFromURL = params.get('kirkegard');
    if (graveyardIdFromURL) {
      setGraveyardId(parseInt(graveyardIdFromURL));
    }
  };

  const handleSearchChange = (e) => setSearch(e.target.value);
  const handleVisitTypeChange = (e) => setVisitType(e.target.value);
  const handleLimitChange = (e) => setLimit(e.target.value);
  const handleSeenByCustomerChange = (e) => setSeenByCustomer(e.target.value);
  const handleSeenByUserChange = (e) => setSeenByUser(e.target.value);
  const handleGraveyardChange = (e) => setGraveyardId(e.target.value);

  useEffect(() => {
    const fetchComments = async () => {
      setIsLoading(true);
      try {
        const queryParams = new URLSearchParams({
          gravestone_id,
          search,
          sort,
          order,
          page,
          limit,
          seen_by_customer,
          seen_by_user,
          visit_type,
        }).toString();
        const response = await fetch(`${apiURL}/api/visit_comments?${queryParams}`);
        if (!response.ok) {
          throw new Error('Something went wrong!');
        }
        const data = await response.json();
        setComments(data.visit_comments);
        setTotalPages(data.totalPages);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
      checkParams();
    };

    fetchComments();
  }, [gravestone_id, search, sort, order, page, limit, seen_by_customer, seen_by_user, visit_type, isModalOpen]);

  const handleSort = (field) => {
    if (sort === field) {
      setOrder(order === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setSort(field);
      setOrder('ASC');
    }
  };

  const openCommentModal = (row) => {
    setSelectedComment(row);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedComment(null);
  };

  if (error) return <div className="text-red-500 text-center">Error: {error}</div>;

  return (
    <PageLayout>
      <ReusableHeaderV2 title="Tilbakemeldinger" icon={FaComments} />
      <Topbar>
        <Toolbar>
          <ReusableLimit value={limit} onChange={handleLimitChange} />
          <MarkAllSeenButton />
          <ReusableSearch value={search} onChange={handleSearchChange} />
        </Toolbar>
      </Topbar>
      {isLoading && <Spinner />}
      <VisitCommentsTable comments={comments} onCommentClick={openCommentModal} /> {/* Pass the click handler */}
      <Pagination totalPages={totalPages} currentPage={page} setPage={setPage} />

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        {selectedComment && (
          <VisitComment
            visit_id={selectedComment.visit_id}
            visit_type={selectedComment.visit_type}
            gravestone_id={selectedComment.gravestone_id}
            user_id={user.id}
            showAutoResponses={true}
          />
        )}
      </Modal>
    </PageLayout>
  );
}

export default VisitComments;