// hooks/useInitiateVisit.js
import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const useInitiateVisit = () => {
    const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  const handleInitiateVisit = async (gravestoneId, workorderId = null) => {
    const userId = user.id; // Assuming the user ID is available in the UserContext

    try {
      const response = await fetch(`${apiURL}/api/visits/initiate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ gravestoneId, workorderId, userId }),
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.message || "Could not initiate or fetch visit");

      
      navigate(`/besok/${data.id}/endre`);
    } catch (err) {
      console.error("Error initiating visit:", err.message);
      // Handle error (e.g., show an error message)
    }
  };

  return handleInitiateVisit;
};

export default useInitiateVisit;