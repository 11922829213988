import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner";
import Topbar from "../../components/Topbar";
import Toolbar from "../../components/Toolbar";
import ReusableSearch from "../../components/ReusableSearch";
import ReusableTable from "../../components/ReusableTable";
import Pagination from "../../components/Pagination";
import ReusableLimit from "../../components/ReusableLimit";
import ReusableActive from "../../components/ReusableActive";
import PageLayout from "../../layouts/PageLayout";
import ReusableHeaderV2 from "../../components/ReusableHeaderV2";
import { useGraveyards } from "../../hooks";
import { FaMapMarkedAlt } from "react-icons/fa";
import { FaPenToSquare } from "react-icons/fa6";

function useWindowSize() {
  const [size, setSize] = useState([window.innerWidth]);
  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerWidth]);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return size;
}

function Graveyards() {
  const [width] = useWindowSize(); // Get the current window width
  const isMobile = width < 768; // Define mobile view threshold
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("name");
  const [order, setOrder] = useState("ASC");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(isMobile ? 500 : 200);
  const [active, setActive] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const navigate = useNavigate();

  const { fetchGraveyards, graveyards, isLoading, error } = useGraveyards(); // Use the hook

  // Event handlers remain within the parent component
  const handleSearchChange = (e) => setSearch(e.target.value);
  const handleLimitChange = (e) => setLimit(e.target.value);
  const handleActiveChange = (e) => setActive(e.target.value);

  const apiURL = process.env.REACT_APP_API_BASE_URL || "";
  const baseURL = "https://cms.gravstellerne.no";

  useEffect(() => {
    fetchGraveyards({ search, sort, order, page, limit, active });
  }, [search, sort, order, page, limit, active]);

  const handleSort = (field) => {
    if (sort === field) {
      setOrder(order === "ASC" ? "DESC" : "ASC");
    } else {
      setSort(field);
      setOrder("ASC");
    }
  };

  const handleRowRightClick = (row) => {
    return [
      {
        label: "Endre",
        icon: FaPenToSquare,
        onClick: () => navigate(`/kirkegarder/${row.id}/endre`),
      },
    ];
  };

  // Adjust columns as per your graveyard data structure
  const columns = [
    {
      label: "Navn",
      accessor: "name",
      link: (row) => `/kirkegarder/${row.id}`,
      width: "200px",
    },
    { label: "Lokasjon", accessor: "location", width: "150px" },
    {
      label: "Gravsteiner",
      accessor: "gravestone_count",
      link: (row) => `/gravsteiner?kirkegard=${row.id}`,
      width: "100px",
    }, // This assumes your API provides a count of gravestones
    { label: "Aktiv?", accessor: "active", type: "boolean", width: "100px" },
    // Add more columns as needed
  ];

  if (error)
    return <div className="text-red-500 text-center">Error: {error}</div>;

  return (
    <PageLayout>
      {isMobile ? (
        <>
          <header className="fixed top-0 left-0 right-0 bg-white z-10 shadow-md p-2 flex justify-between items-center">
            <h1 className="text-lg font-bold">Kirkegårder</h1>
            <ReusableSearch
              value={search}
              onChange={handleSearchChange}
              className="ml-2"
            />
          </header>

          <div className="pt-14 pb-2 space-y-3 overflow-y-auto">
            {isLoading && <Spinner />}
            {graveyards.map((graveyard) => (
              <Link
                to={`/kirkegarder/${graveyard.id}`}
                key={graveyard.id}
                className="flex items-center bg-white rounded-lg shadow hover:shadow-lg transition-shadow duration-200 ease-in-out overflow-hidden"
              >
                <div className="flex-shrink-0">
                  <img
                    src={baseURL + graveyard.picture}
                    alt={`${graveyard.name} thumbnail`}
                    className="h-16 w-16 md:h-20 md:w-20 object-cover"
                  />
                  {!graveyard.picture && (
                    <div className="h-16 w-16 md:h-20 md:w-20 flex items-center justify-center bg-gray-200 text-gray-500">
                      {/* Placeholder icon or text */}
                    </div>
                  )}
                </div>
                <div className="p-2 flex-1">
                  <h5 className="text-sm md:text-md font-bold">
                    {graveyard.name}
                  </h5>
                  <p className="text-xs text-gray-600">
                    {graveyard.gravestone_count} gravplasser
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </>
      ) : (
        <>
          <ReusableHeaderV2
            title="Kirkegårder"
            linkTo="/kirkegarder/ny"
            icon={FaMapMarkedAlt}
          />
          <Topbar>
            <Toolbar>
              <ReusableLimit value={limit} onChange={handleLimitChange} />
              <ReusableActive value={active} onChange={handleActiveChange} />
              <ReusableSearch value={search} onChange={handleSearchChange} />
            </Toolbar>
          </Topbar>
          <ReusableTable
            columns={columns}
            data={graveyards}
            sort={{ field: sort, order: order }}
            onSort={handleSort}
            onRowRightClick={handleRowRightClick}
            isLoading={isLoading}
          />
          <Pagination
            totalPages={totalPages}
            currentPage={page}
            setPage={setPage}
          />
        </>
      )}
    </PageLayout>
  );
}

export default Graveyards;
