import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { FaRegCalendarAlt, FaTools } from 'react-icons/fa';
import axiosInstance from '../../utils/axiosInstance';
import Rating from '../../components/Rating';
import VisitComment from '../../components/VisitComment';
import { AuthContext } from '../../context/AuthContext';
import ImageWithFallback from '../../components/ImageWithFallback';

const VisitDetailPage = () => {
  const { visitId, isLegacy } = useParams();
  const [visitDetails, setVisitDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [zoomPosition, setZoomPosition] = useState(null);

  const { user } = useContext(AuthContext);
  const modalImageRef = useRef(null);

  const fetchVisitDetails = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/api/visits/${visitId}`, {
        params: { is_legacy: isLegacy },
      });
      setVisitDetails(response.data);
    } catch (err) {
      setError(err.message || 'Failed to fetch visit details');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVisitDetails();
  }, [visitId, isLegacy]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const visitDate = visitDetails?.visitDetails?.visit_date || visitDetails.visitDetails.visit_date;
  const description = visitDetails?.visitDetails?.description || visitDetails.visitDetails.description;
  const afterPicture = visitDetails?.visitDetails?.after_picture_url || visitDetails.visitDetails.after_pictures;
  const beforePicture = visitDetails?.visitDetails?.before_picture_url || visitDetails.visitDetails.before_pictures;
  const activities = isLegacy === '1'
    ? Array.isArray(visitDetails?.visitDetails?.activities)
      ? visitDetails.visitDetails.activities
      : []
    : Array.isArray(visitDetails?.selectedActivities)
      ? visitDetails.selectedActivities.map((activity) => activity.name)
      : visitDetails?.activities || [];
  const workerName = visitDetails.worker_name || (isLegacy === '1' && visitDetails.full_worker_name);

  const handleImageClick = (image) => {
    
    setSelectedImage(image);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
    setZoomPosition(null);
  };

  const handleMouseMove = (e) => {
    const { left, top, width, height } = modalImageRef.current.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setZoomPosition({ x: e.clientX, y: e.clientY, bgX: x, bgY: y });
  };

  const handleMouseLeave = () => setZoomPosition(null);

  return (
    <div className="visit-detail-page py-8">
      <div className="max-w-full mx-auto bg-white shadow overflow-hidden sm:rounded-lg grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-white p-6">
          <div className="px-4 py-5 sm:px-6 border-b">
            <h1 className="text-lg font-medium text-gray-900">
              {isLegacy === '1' ? 'Legacy Besøksdetaljer' : 'Besøksdetaljer'}
            </h1>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {isLegacy === '1' ? 'Informasjon fra et tidligere besøk' : 'Informasjon fra besøket'}
            </p>
          </div>
          {visitDetails && (
            <>
              <div className="bg-gray-50 px-4 py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500 sm:mb-4">Bilder</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                  <div className="flex flex-wrap -mx-2 overflow-hidden">
                    <div className="my-2 px-2 w-full overflow-hidden sm:w-1/2 relative">
                      <ImageWithFallback
                        src={afterPicture}
                        fallbackSrc="/images/default_after.jpg"
                        alt="After the visit"
                        className="object-cover rounded-lg shadow-lg mx-auto cursor-pointer"
                        onClick={handleImageClick}
                      />
                      <p className="text-center text-sm mt-2">Etter</p>
                    </div>
                    <div className="my-2 px-2 w-full overflow-hidden sm:w-1/2 relative">
                      <ImageWithFallback
                        src={beforePicture}
                        fallbackSrc="/images/default_before.jpg"
                        alt="Before the visit"
                        className="object-cover rounded-lg shadow-lg mx-auto cursor-pointer"
                        onClick={handleImageClick}
                      />
                      <p className="text-center text-sm mt-2">Før</p>
                    </div>
                  </div>
                </dd>
              </div>
              <div className="border-t border-gray-200">
                <dl>
                  {/* Description, Date, Activities, and Worker Name */}
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Beskrivelse</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {description || 'Ingen beskrivelse tilgjengelig'}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Besøksdato</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <FaRegCalendarAlt className="inline mr-2 text-blue-500" />
                      {new Date(visitDate).toLocaleDateString("nb-NO")}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Dette ble gjort</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <FaTools className="inline mr-2 text-green-600" />
                      {activities?.join(', ') || 'Ingen aktiviteter registrert'}
                    </dd>
                  </div>
                  {workerName && (
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Utført av</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{workerName}</dd>
                    </div>
                  )}
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Vurdering</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <Rating
                        visit_type={isLegacy === '1' ? 'visits' : 'visits_new'}
                        visit_id={visitId}
                        gravestone_id={visitDetails.gravestone_id}
                        customer_id={visitDetails.customer_id}
                        readOnly={true}
                      />
                    </dd>
                  </div>
                </dl>
              </div>
            </>
          )}
        </div>

        {/* Right Side: Comments */}
        <div className="bg-white p-6 border-l">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Kommentarer</h2>
          <VisitComment
            visit_id={visitId}
            visit_type={isLegacy === '1' ? 'visits' : 'visits_new'}
            gravestone_id={visitDetails.gravestone_id}
            user_id={user.id}
            readOnly={false}
          />
        </div>
      </div>

      {/* Modal for full-size image with zoom */}
      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeImageModal}
        >
          <div className="relative">
            <img
              src={selectedImage}
              alt="Full-size"
              className="max-w-screen max-h-screen object-contain rounded-lg shadow-lg cursor-none"
              ref={modalImageRef}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              style={{ cursor: 'none' }}
            />
            {zoomPosition && (
              <div
                className="absolute pointer-events-none"
                style={{
                  width: "400px",
                  height: "400px",
                  backgroundImage: `url(${selectedImage})`,
                  backgroundPosition: `${zoomPosition.bgX}% ${zoomPosition.bgY}%`,
                  backgroundSize: "600%",
                  border: "1px solid #fff",
                  top: `${zoomPosition.y - 200}px`,
                  left: `${zoomPosition.x - 200}px`,
                  zIndex: 10,
                }}
              />
            )}
            <button
              className="absolute top-2 right-2 text-white text-xl font-bold"
              onClick={closeImageModal}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default VisitDetailPage;