// src/components/WorkorderSkeleton.js

import React from 'react';
import ContentLoader from 'react-content-loader';

const WorkorderSkeleton = (props) => (
  <div className="bg-white rounded-lg overflow-hidden shadow-md">
    <div className="p-6">
      <ContentLoader
        speed={2}
        width="100%"
        height={160}
        viewBox="0 0 400 160"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        {/* Title */}
        <rect x="0" y="0" rx="4" ry="4" width="70%" height="20" />

        {/* Progress Bar */}
        <rect x="0" y="30" rx="4" ry="4" width="100%" height="10" />

        {/* Start Date */}
        <rect x="0" y="50" rx="4" ry="4" width="50%" height="15" />

        {/* Due Date */}
        <rect x="0" y="70" rx="4" ry="4" width="50%" height="15" />

        {/* Graveyard Names */}
        <rect x="0" y="90" rx="4" ry="4" width="80%" height="15" />

        {/* Gravestone Count */}
        <rect x="0" y="110" rx="4" ry="4" width="60%" height="15" />

        {/* Status */}
        <rect x="0" y="130" rx="4" ry="4" width="40%" height="15" />

        {/* Activities */}
        <rect x="0" y="150" rx="4" ry="4" width="90%" height="15" />
      </ContentLoader>
    </div>
  </div>
);

export default WorkorderSkeleton;