import React from "react";

const FollowUpModal = ({ 
  isOpen, 
  onClose, 
  onConfirm, 
  onCancel, 
  comment, 
  setComment 
}) => {
  if (!isOpen) return null;

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      // Lukk modalen kun hvis du klikker direkte på bakgrunnen
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      onClick={handleBackdropClick} // Fest klikkhåndteringen til bakgrunnen
    >
      <div className="bg-white rounded-lg p-6 w-96">
        <h2 className="text-lg font-semibold mb-4">Legg til oppfølging</h2>
        <textarea
          className="w-full border p-2 rounded-lg mb-4"
          placeholder="Legg til en kommentar (valgfritt)"
          value={comment}
          onChange={(e) => setComment(e.target.value)} // Oppdaterer kommentarstate
        />
        <div className="flex flex-col space-y-4">
          <button
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg"
            onClick={onCancel}
          >
            Slett oppfølging
          </button>
          <button
            className="px-4 py-2 bg-green-500 text-white rounded-lg"
            onClick={() => onConfirm(comment)}
          >
            {comment ? "Bekreft" : "Bekreft uten kommentar"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FollowUpModal;