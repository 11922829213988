import {
  FaUserFriends,
  FaMapMarkedAlt,
  FaMonument,
  FaUsersCog,
  FaClipboardList,
  FaRegCalendarCheck,
  FaComments,
  FaBuilding,
  FaPaintBrush,
  FaUser,
  FaCog,
} from "react-icons/fa";

import { FaTableList } from "react-icons/fa6";

const menuItemsConfig = [
  { icon: FaUserFriends, label: "Kunder", to: "/kunder", userType: "admin" },
  { icon: FaMapMarkedAlt, label: "Kirkegårder", to: "/kirkegarder" },
  { icon: FaMonument, label: "Gravsteiner", to: "/gravsteiner",
    children: [
      { label: "Oppfølging", to: "/oppfolging" },
      { label: "Spesialdager", to: "/spesialdager" },
    ],
   },
  { icon: FaUsersCog, label: "Gravpersoner", to: "/gravpersoner" },
  {
    icon: FaClipboardList,
    label: "Arbeidslister",
    to: "/arbeidslister",
    userType: "admin",
  },
  {
    icon: FaRegCalendarCheck,
    label: "Besøk",
    to: "/besok",
    userType: "admin",
    children: [
      { label: "Godkjente Besøk", to: "/besok" },
      { label: "Ikke Godkjent", to: "/besok/tommel-ned" },
    ],
  },
  {
    icon: FaComments,
    label: "Tilbakemeldinger",
    to: "/tilbakemeldinger",
    userType: "admin",
    hasNotification: true, // Notification state to be overridden in Sidebar
  },
  {
    icon: FaBuilding,
    label: "Avdelinger",
    to: "/avdelinger",
    userType: "admin",
  },
  {
    icon: FaTableList,
    label: "Logger",
    to: "/logger",
    userType: "admin",
    children: [
      { label: "SMS", to: "/logger?tab=sms" },
      { label: "E-post", to: "/logger?tab=epost" },
      { label: "Timer", to: "/logger?tab=timer" },
    ],
  },
  {
    icon: FaPaintBrush,
    label: "Maler",
    to: "/maler",
    userType: "admin",
    children: [
      { label: "SMS", to: "/maler?tab=sms" },
      { label: "E-post", to: "/maler?tab=epost" },
    ],
  },
  { icon: FaUser, label: "Brukere", to: "/brukere", userType: "admin" },
  {
    icon: FaCog,
    label: "Innstillinger",
    to: "/innstillinger",
    userType: "admin",
    children: [
      { label: "Generelt", to: "/innstillinger?tab=generelt" },
      { label: "Produkter", to: "/innstillinger?tab=produkter" },
      { label: "Aktiviteter", to: "/innstillinger?tab=aktiviteter" },
      { label: "Kundemelding", to: "/innstillinger?tab=kundemelding" },
      { label: "Kart", to: "/innstillinger?tab=kart" },
      { label: "Varsler", to: "/innstillinger?tab=varsler" },
      { label: "Kontakt", to: "/innstillinger?tab=kontakt" },
      { label: "Planlagte Jobber", to: "/innstillinger?tab=planlagte-jobber" },
    ],
  },
];

export default menuItemsConfig;
